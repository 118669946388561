import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./style.css";

// import icons
import { IconContext } from "react-icons";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import AnalyticsService from "../../Services/Analytics";

export default function Footer(props) {
  const { t } = useTranslation();

  const history = useHistory();

  const handleSocialLink = async (link) => {
    await AnalyticsService.gaEvent(
      "Home-socials",
      `outbound link`,
      `redirect to ${link}`,
      1,
    );
    window.open(link, "_blank", "noopener, noreferrer");
  };

  return (
    <div
      className="contact-info container p-0"
      style={{ maxWidth: props.maxWidth, paddingBottom: 0 }}
    >
      <div className="row">
        <div className="col-md-3 div1 div-common">
          <h5>{t("footer_section_1_title")}</h5>
          <p>{t("footer_section_1_body")}</p>
        </div>
        <div className="col-md-3 div2 div-common">
          <h5>{t("footer_section_2_title")}</h5>
          <p>{t("footer_section_2_body")}</p>
        </div>
        <div className="col-md-3 div3 div-common">
          <h5>{t("footer_section_3_title")}</h5>
          <p>{t("footer_section_3_body")}</p>
        </div>
        <div className="col-md-3 div4 div-common">
          <h5>{t("footer_section_4_title")}</h5>
          <IconContext.Provider value={{ size: "27px" }}>
            <div className="icon-container">
              <FaFacebook
                className="icon icon-facebook"
                onClick={() =>
                  handleSocialLink("https://www.facebook.com/StandWeSpeak/")
                }
                name="fb-icon"
              />
              <GrTwitter
                className="icon icon-twitter"
                onClick={() =>
                  handleSocialLink("https://twitter.com/StandWeSpeak")
                }
                name="twitter-icon"
              />
              <FaYoutube
                className="icon icon-youtube"
                onClick={() =>
                  handleSocialLink(
                    "https://www.youtube.com/channel/UCYr6_FIwbHOL_PyMxgF-8EQ",
                  )
                }
                name="youtube-icon"
              />
              <FaInstagram
                className="icon icon-instagram"
                onClick={() =>
                  handleSocialLink(
                    "https://www.instagram.com/standwespeak.sextech/",
                  )
                }
                name="insta-icon"
              />
              <FaLinkedinIn
                className="icon icon-linkedIn"
                onClick={() =>
                  handleSocialLink(
                    "https://www.linkedin.com/company/standwespeak/",
                  )
                }
                name="Ln-icon"
              />
            </div>
          </IconContext.Provider>
          <div className="policy pt-2">
            <span
              className="tnc-link mr-2 p-0"
              onClick={async () => {
                await AnalyticsService.gaEvent(
                  "Home-footer",
                  `outbound link`,
                  `opened terms and condition page`,
                  1,
                );
                history.push("/TermsConditions");
              }}
            >
              {t("footer_section_4_body", { returnObjects: true })?.[0]}
            </span>
            <span
              className="tnc-link mr-2 p-0"
              onClick={async () => {
                await AnalyticsService.gaEvent(
                  "Home-footer",
                  `outbound link`,
                  `opened privacy policy page`,
                  1,
                );
                history.push("/Privacy");
              }}
            >
              {t("footer_section_4_body", { returnObjects: true })?.[1]}
            </span>
          </div>
          <div className="policy pb-2">
            <span
              className="tnc-link mr-2 p-0"
              onClick={async () => {
                await AnalyticsService.gaEvent(
                  "Home-footer",
                  `outbound link`,
                  `opened Refund page`,
                  1,
                );
                history.push("/Refund");
              }}
            >
              {t("footer_section_4_body", { returnObjects: true })?.[2]}
            </span>
            <span
              className="tnc-link mr-2 p-0"
              onClick={async () => {
                await AnalyticsService.gaEvent(
                  "Home-footer",
                  `outbound link`,
                  `opened Shipping page`,
                  1,
                );
                history.push("/Shipping");
              }}
            >
              {t("footer_section_4_body", { returnObjects: true })?.[3]}
            </span>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <p>
          {t("developer")} @NevetsKuro <br /> Check out: https://stevenferns.in
        </p>
      </div>
    </div>
  );
}
