const smoothScrollTo = (id) => {
  let rn = document.getElementById(id);
  if (rn) {
    rn.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};
const getUrlVars = () => {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    },
  );
  return vars;
};

const redirectToSite = (link) => {
  window.open(link);
};

const redirectToSiteSelf = (link) => {
  window.open(link, "_self");
};

const makeLowerCase = (value) => {
  value = value != null ? value.toLowerCase() : "";
  return value;
};

const makeUpperCase = (value) => {
  value = value != null ? value.toUpperCase() : "";
  return value;
};

const getUniqueValuesByKey = (data) => {
  data
    .map((item) => item.topic)
    .filter((value, index, self) => self.indexOf(value) === index);
  return data;
};

const changeDateFormat = (date) => {
  if (date) {
    let dt = date.slice(0, 10).split("-");
    return dt[2] + "/" + dt[1] + "/" + dt[0];
  }
  return date;
};

const changeTimeFormat = (date) => {
  let dt = date.slice(12, 16).split(":");
  if (parseInt(dt[0]) / 12 === 0) {
    return dt[0] + ":" + dt[1] + "am";
  } else {
    return (dt[0] % 12) + ":" + dt[1] + "pm";
  }
};

const hasEmptyFields = (obj) => {
  for (var key in obj) {
    if (obj[key] === null || obj[key] === "") return false;
  }
  return true;
};

const getTime = () => {
  var date, TimeType, hour, minutes, fullTime;
  date = new Date();
  hour = date.getHours();
  minutes = date.getMinutes();
  if (hour <= 11) {
    TimeType = "AM";
  } else {
    TimeType = "PM";
  }

  if (hour > 12) {
    hour = hour - 12;
  }
  if (hour === 0) {
    hour = 12;
  }

  if (minutes < 10) {
    minutes = "0" + minutes.toString();
  }
  fullTime =
    hour.toString() + ":" + minutes.toString() + " " + TimeType.toString();
  return fullTime;
};

const getContentByCategory = (
  chatbotContents,
  category,
  enterpriseCategory,
) => {
  const contents = Array.from(chatbotContents).find(
    (v) => v.enterprise === enterpriseCategory,
  ).content;

  return {
    linked_videos: contents.videos.find((content) =>
      content.category.includes(category),
    ),
    linked_followups: contents.followups.find((content) =>
      content.category.includes(category),
    ),
    linked_product: contents.products.find((content) =>
      content.category.includes(category),
    ),
    linked_resources: contents.resources.find((content) =>
      content.category.includes(category),
    ),
    linked_consults: contents.consultants.find((content) =>
      content.category.includes(category),
    ),
  };
};

export {
  changeTimeFormat,
  changeDateFormat,
  getUrlVars,
  redirectToSite,
  redirectToSiteSelf,
  makeLowerCase,
  makeUpperCase,
  getUniqueValuesByKey,
  smoothScrollTo,
  hasEmptyFields,
  getTime,
  getContentByCategory,
};
