import React, { Fragment, useEffect } from "react";
import "./style.css";

function Refund() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <div className="wrapper serviceBody">
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>1. Teleconsultations</h1>
                <p>
                  Once a session has been booked, it cannot get cancelled. Any
                  appointment or session can be rescheduled only once with the
                  same consultant or expert. No refunds will be processed in
                  case of client no-shows. In case the consultant or the expert
                  fails to show up, the client will have the choice of a
                  reschedule or a complete refund. In case there are delays from
                  the client’s end, StandWeSpeak will not be liable and will not
                  be able to provide a(n) refund/free reschedule/extended
                  appointment duration et al. Please contact us at
                  info@standwespeak.com in case you have any questions.
                </p>
                <h1>Third-party services</h1>
                <p>
                  StandWeSpeak/Mae is not responsible for any purchase or
                  payments made for the third-party services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Refund;
