import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import TeamMembers from "./member.js";
import Mentors from "./mentor.js";
import { Helmet } from "react-helmet";

//import images
// import Priyal from "../../Assets/Images/Priyal(400x400).jpg";
// import Priyal from "../../Assets/Images/media/priyal/priyal.png";
import Priyal from "../../Assets/Images/media/priyal/priyal_2.png";
// import Priyal from "../../Assets/Images/media/priyal/priyal_3.png";
// import Priyal from "../../Assets/Images/media/priyal/priyal_4.png";
import Footer from "../../Components/Footer/index.js";

import "./style.css";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>StandWeSpeak | Core Team</title>
        <meta name="title" content="StandWeSpeak | Core Team" />
        <meta
          name="description"
          content="Meet the dedicated team that is passionate about bringing a positive change in people's lives."
        />
        <meta
          name="keywords"
          content="Team, Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <div className="bg-founder">
        <div>
          <div className="heading-container">
            <h1 className="flx" style={{ fontSize: "115px" }}>
              Our Founder
            </h1>
            <h3 style={{ color: "white" }}>The Founder</h3>
          </div>
          <p
            className="tag-line"
            style={{ color: "white", marginTop: "-18px" }}
          >
            "You don't have to know how to do it. You just have to know that you
            can." - Bob Proctor
          </p>
        </div>
        <Container
          fluid
          style={{
            maxWidth: "1140px",
            marginTop: "150px",
            marginBottom: "150px",
          }}
        >
          <Row>
            <Col
              lg={"4"}
              className="founder-img"
              style={{ paddingRight: "0px" }}
            >
              <img src={Priyal} alt="Priyal" style={{ margin: "auto" }} />
            </Col>
            <Col lg={"8"}>
              <Card className="card-founder">
                <p>
                  Priyal Agrawal is passionate about ensuring that young people
                  have access to information and sexual health services,
                  required to gain complete control over their bodies and lives.
                </p>
                <p>
                  Her journey - from a naïve little girl to a free-thinking
                  millennial and now a social changemaker - was only facilitated
                  by her education and background in Psychology; her experiences
                  in social and mental health industry; the times when she met
                  people from different backgrounds facing similar issues; and
                  exposure to the prevailing orthodox culture, misbeliefs and
                  sexual health practices in India.
                </p>
                <p>
                  Dedicated and devoted entirely to bring a paradigm shift in
                  the mindset of people and the way children receive sex
                  education around the globe; Priyal founded StandWeSpeak with a
                  vision and aim to promote social and gender equality and
                  create a positive systematic change in the society.
                </p>
                <h6>PRIYAL AGRAWAL</h6>
                <p style={{ marginBottom: "0px", color: "#2b2b2b" }}>
                  Founder & CEO
                </p>
              </Card>
              <div id="card-founder-gradient-bottom"></div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <TeamMembers />
      </div>
      <div>
        <Mentors />
      </div>

      <Footer maxWidth="97vw" />
    </React.Fragment>
  );
};
