import products from "./chatbotList/products";
import videos from "./chatbotList/videos";
import resources from "./chatbotList/resources";
import followups from "./chatbotList/followups";
import consultants from "./chatbotList/consultants";
import { ENTERPRISE_TYPES } from "../types/chatbot";

export default [
  {
    enterprise: ENTERPRISE_TYPES.SWS,
    content: {
      videos,
      products,
      resources,
      consultants,
      followups,
    },
  },
  {
    enterprise: ENTERPRISE_TYPES.MANSURI,
    content: {
      videos: [],
      products,
      resources,
      consultants,
      followups,
    },
  },
  {
    enterprise: ENTERPRISE_TYPES.SCHOOLS,
    content: {
      videos,
      products,
      resources,
      consultants,
      followups,
    },
  },
];
