import { CHATBOT_TOPICS } from "../../types/chatbot";

// Abortion & Health Services
const healthVideos = [
  {
    vid: 1,
    video_title: "What Is An Abortion?",
    video_url: "https://www.youtube.com/embed/GdHG_VzE2nE",
  },
  {
    vid: 2,
    video_title: "What Is Emergency Contraception? (The Morning After Pill)",
    video_url: "https://www.youtube.com/embed/Z1LTban3Z84",
  },
  {
    vid: 3,
    video_title: "Am I Pregnant?",
    video_url: "https://player.vimeo.com/video/585115527?h=6e272850ee",
  },
  {
    vid: 4,
    video_title: "How many weeks is my pregnancy?",
    video_url: "https://player.vimeo.com/video/585102447?h=ec84c8ef5a",
  },
  {
    vid: 5,
    video_title: "How does an abortion with pills work?",
    video_url: "https://player.vimeo.com/video/585077520?h=335269e007",
  },
  {
    vid: 6,
    video_title:
      "How do I take mifepristone and misoprostol to have an abortion before 13 weeks?",
    video_url: "https://player.vimeo.com/video/585124321?h=73db2416b2",
  },
  {
    vid: 7,
    video_title:
      "How do I take only misoprostol to have an abortion before 13 weeks?",
    video_url: "https://player.vimeo.com/video/585120154?h=7518dccc9b",
  },
  {
    vid: 8,
    video_title: "What will I experience during an abortion with pills?",
    video_url: "https://player.vimeo.com/video/585087536?h=6f4c09964a",
  },
  {
    vid: 9,
    video_title:
      "What are signs of complication during an abortion with pills?",
    video_url: "https://player.vimeo.com/video/585108920?h=12076f4a1b",
  },
  {
    vid: 10,
    video_title: "What happens after I have an abortion with pills?",
    video_url: "https://player.vimeo.com/video/585095427?h=a60d1001de",
  },
];

// Bodies and development
const bodiesVideos = [
  {
    vid: 1,
    video_title: "Bodies: Different Shapes and Sizes. All Beautiful!",
    video_url: "https://www.youtube.com/embed/Fcy_VQZG5Dg",
  },
  {
    vid: 2,
    video_title: "Why Don't I Like The Way I Look?",
    video_url: "https://www.youtube.com/embed/v7zUHOEYlN8",
  },
  {
    vid: 3,
    video_title: "Is It OK To Shave Your Vulva?",
    video_url: "https://www.youtube.com/embed/WTAxQGwMdwo",
  },
  {
    vid: 4,
    video_title: "Virginity",
    video_url: "https://www.youtube.com/embed/W7jhGB63TPo",
  },
  {
    vid: 5,
    video_title: "What Is Vaginal Discharge?",
    video_url: "https://www.youtube.com/embed/3j3PNCO6Ehs",
  },
  {
    vid: 6,
    video_title: "Anatomy: Assigned Sex At Birth (Female)",
    video_url: "https://www.youtube.com/embed/j9QgcCK6FKM",
  },
  {
    vid: 7,
    video_title: "Anatomy: Assigned Sex At Birth (Male)",
    video_url: "https://www.youtube.com/embed/G2ciOhidKpg",
  },
  {
    vid: 8,
    video_title: "Growing Up: Awkward Conversations",
    video_url: "https://www.youtube.com/embed/diNKAEUK3Hs",
  },
  {
    vid: 9,
    video_title:
      "Conversations to Have with an Adult: Puberty, Emotions and more!",
    video_url: "https://www.youtube.com/embed/Ib8qHBR0LK8",
  },
  {
    vid: 10,
    video_title: "I Have One Testicle. Is That Normal?",
    video_url: "https://www.youtube.com/embed/-MBFtKo89Yg",
  },
  {
    vid: 11,
    video_title: "Disability And Sexuality",
    video_url: "https://www.youtube.com/embed/MfYSpuKq_-8",
  },
  {
    vid: 12,
    video_title: "How To Handle Peer Pressure",
    video_url: "https://www.youtube.com/embed/a6_8zBcm7xk",
  },
  {
    vid: 13,
    video_title: "How The Boner Grows",
    video_url: "https://www.youtube.com/embed/X5XSwr47Xb8",
  },
];

// Menstruation
const menstVideos = [
  {
    vid: 1,
    video_title: "All About Getting Your Period",
    video_url: "https://www.youtube.com/embed/RX4NJrJxwss",
  },
  {
    vid: 2,
    video_title: "Menstruation: What To Expect",
    video_url: "https://www.youtube.com/embed/DBe7-PHRav8",
  },
  {
    vid: 3,
    video_title: "Period Hygiene: Tampons, Pads and Menstrual Cups",
    video_url: "https://www.youtube.com/embed/kmWbOC8Fbb0",
  },
  {
    vid: 4,
    video_title: "Menstruation Sensation: All About Getting Your Period",
    video_url: "https://www.youtube.com/embed/7O4w0GShDns",
  },
  {
    vid: 5,
    video_title: "Period In Third Period",
    video_url: "https://www.youtube.com/embed/M7N5_SYZPFQ",
  },
  {
    vid: 6,
    video_title: "Puberty in Girls: Boobs and More",
    video_url: "https://www.youtube.com/embed/umpBnIxOqy8",
  },
  {
    vid: 7,
    video_title: "Anatomy: Assigned Sex At Birth (Female)",
    video_url: "https://www.youtube.com/embed/j9QgcCK6FKM",
  },
  {
    vid: 8,
    video_title: "Is It OK To Shave Your Vulva?",
    video_url: "https://www.youtube.com/embed/WTAxQGwMdwo",
  },
  {
    vid: 9,
    video_title: "Top Signs Girls are in Puberty",
    video_url: "https://www.youtube.com/embed/r6oka_hqMJk",
  },
];

// Parent’s guide
const parentsVideos = [
  {
    vid: 1,
    video_title: "Talking With Your Parents About Sex",
    video_url: "https://www.youtube.com/embed/1wg_a0HCqvA",
  },
  {
    vid: 2,
    video_title: "Where Do Babies Come From?",
    video_url: "https://www.youtube.com/embed/-1bDV2Kx88I",
  },
  {
    vid: 3,
    video_title: "Little kids. Big questions.",
    video_url: "https://www.youtube.com/embed/4dXdszFN0Cs",
  },
  {
    vid: 4,
    video_title: "How Do You Talk To Young Kids About Sex?",
    video_url: "https://www.youtube.com/embed/YbtXbnkP690",
  },
  {
    vid: 5,
    video_title: "What Should I Do When...?",
    video_url: "https://www.youtube.com/embed/NxouBlgMwSo",
  },
  {
    vid: 6,
    video_title: "Is Playing Doctor OK?",
    video_url: "https://www.youtube.com/embed/-ifQmTaAOP8",
  },
  {
    vid: 7,
    video_title: "When should you start talking to kids about sexuality?",
    video_url: "https://www.youtube.com/embed/wXAQ3-_kRpM",
  },
  {
    vid: 8,
    video_title: "Help kids learn how bodies are mostly alike",
    video_url: "https://www.youtube.com/embed/vI86yCvUrAE",
  },
  {
    vid: 9,
    video_title: "Help kids learn where babies come from",
    video_url: "https://www.youtube.com/embed/sOL8dbiRhpg",
  },
  {
    vid: 10,
    video_title: "Help kids learn about gender",
    video_url: "https://www.youtube.com/embed/St6t1WvbysU",
  },
];

// Gender and Sexuality
const genderVideos = [
  {
    vid: 1,
    video_title: "Gender Roles and Stereotypes",
    video_url: "https://www.youtube.com/embed/Ulh0DnFUGsk",
  },
  {
    vid: 2,
    video_title:
      "Gender Identity: Being Female, Male, Transgender or Genderfluid",
    video_url: "https://www.youtube.com/embed/W9YwOE8ndnc",
  },
  {
    vid: 3,
    video_title:
      "Sexual Orientations Explained: Lesbian, Gay, Heterosexual and Bisexual",
    video_url: "https://www.youtube.com/embed/P5x5Fo7rMvY",
  },
  {
    vid: 4,
    video_title: "Coming Out LGBTQ+",
    video_url: "https://www.youtube.com/embed/7YXcg8HJs18",
  },
  {
    vid: 5,
    video_title: "What is Sexual Orientation? LGBTQ+",
    video_url: "https://www.youtube.com/embed/wrUYs2FnrgA",
  },
  {
    vid: 6,
    video_title:
      "Sex Assigned at Birth and Gender Identity: What Is The Difference?",
    video_url: "https://www.youtube.com/embed/Y19kYh6k7ls",
  },
  {
    vid: 7,
    video_title: "Gender Identity Explained",
    video_url: "https://www.youtube.com/embed/S231LZ3bC3A",
  },
  {
    vid: 8,
    video_title: "What Is Sexuality?",
    video_url: "https://www.youtube.com/embed/ztVAqPmYlU0",
  },
  {
    vid: 9,
    video_title: "What Is Transgender?",
    video_url: "https://www.youtube.com/embed/aUVrr7zWakk",
  },
  {
    vid: 10,
    video_title: "How Do Queer Couples Have Babies?",
    video_url: "https://www.youtube.com/embed/qczJ7qSczcQ",
  },
  {
    vid: 11,
    video_title: "How To Be A LGBTQIA+ Ally",
    video_url: "https://www.youtube.com/embed/xS5FMErj0SE",
  },
  {
    vid: 12,
    video_title: "Expressing Myself. My Way.",
    video_url: "https://www.youtube.com/embed/ITRdvGnplLU",
  },
];

// Sex, consent, relationships
const consentVideos = [
  {
    vid: 1,
    video_title: "Are You Ready To Have Sex?",
    video_url: "https://www.youtube.com/embed/LV5IoN-Hds0",
  },
  {
    vid: 2,
    video_title: "What should you do if you've had unprotected sex?",
    video_url: "https://www.youtube.com/embed/48zdZ6x3SK4",
  },
  {
    vid: 3,
    video_title: "Saying Yes or No: What Is Consent?",
    video_url: "https://www.youtube.com/embed/4z9_L9FXA3o",
  },
  {
    vid: 4,
    video_title: "I Had Unprotected Sex And I Didn't Get Pregnant. Why?",
    video_url: "https://www.youtube.com/embed/Qos3DqD3LQY",
  },
  {
    vid: 5,
    video_title: "Can You Break A Boner?",
    video_url: "https://www.youtube.com/embed/g3paLWPZ_eQ",
  },
  {
    vid: 6,
    video_title: "Does Sex Hurt the First Time?",
    video_url: "https://www.youtube.com/embed/CNqOMQPh64Q",
  },
  {
    vid: 7,
    video_title: "First Kiss And Showing Affection",
    video_url: "https://www.youtube.com/embed/VDaFzW-fNLc",
  },
  {
    vid: 8,
    video_title: "How To Deal With Rejection",
    video_url: "https://www.youtube.com/embed/RkZsDqH80Qs",
  },
  {
    vid: 9,
    video_title: "Having Sex: Intimacy and Emotions",
    video_url: "https://www.youtube.com/embed/KhIeor11drE",
  },
  {
    vid: 10,
    video_title: "How to Know if You're in Love",
    video_url: "https://www.youtube.com/embed/KZV38ah3wc8",
  },
  {
    vid: 11,
    video_title: "Love is Love: Free to Be Me",
    video_url: "https://www.youtube.com/embed/cXtsiWoMsxs",
  },
  {
    vid: 12,
    video_title: "Texting: Breaking Up Over Text",
    video_url: "https://www.youtube.com/embed/_hOhwt1EgLc",
  },
  {
    vid: 13,
    video_title: "Healthy vs Unhealthy Relationships",
    video_url: "https://www.youtube.com/embed/Gn7ZQ2x0cOE",
  },
];

// Masturbation
const mastVideos = [
  {
    vid: 1,
    video_title: "Porn Isn't Sex Ed",
    video_url: "https://www.youtube.com/embed/1SCf7fLFdzY",
  },
  {
    vid: 2,
    video_title: "How Many Times Can A Person Masturbate In One Day?",
    video_url: "https://www.youtube.com/embed/50kgHtgblqY",
  },
  {
    vid: 3,
    video_title: "Females and Masturbation",
    video_url: "https://www.youtube.com/embed/5Q7VzPaFOJw",
  },
  {
    vid: 4,
    video_title: "Should I Feel Bad About Watching Porn?",
    video_url: "https://www.youtube.com/embed/6_BeDBNJbwI",
  },
  {
    vid: 5,
    video_title: "Porn: Fact or Fiction",
    video_url: "https://www.youtube.com/embed/GdB2rmGqqNU",
  },
  {
    vid: 6,
    video_title: "Masturbation: Totally normal",
    video_url: "https://www.youtube.com/embed/TK48R722jyA",
  },
  {
    vid: 7,
    video_title: "What is a Wet Dream?",
    video_url: "https://www.youtube.com/embed/uotzoDDRW_s",
  },
  {
    vid: 8,
    video_title: "How The Boner Grows",
    video_url: "https://www.youtube.com/embed/X5XSwr47Xb8",
  },
  {
    vid: 9,
    video_title: "Does Breast Size Really Matter?",
    video_url: "https://www.youtube.com/embed/ZAx5CMyCJnk",
  },
  {
    vid: 10,
    video_title: "Does Penis Size Really Matter?",
    video_url: "https://www.youtube.com/embed/kmYMHhzj1YY",
  },
  {
    vid: 11,
    video_title: "Can Anyone Get An Erection?",
    video_url: "https://www.youtube.com/embed/5433Rwto1WQ",
  },
  {
    vid: 12,
    video_title: "Is It Normal To Watch Porn?",
    video_url: "https://www.youtube.com/embed/sQvTZRZCoPw",
  },
  {
    vid: 13,
    video_title: "First Kiss And Showing Affection",
    video_url: "https://www.youtube.com/embed/VDaFzW-fNLc",
  },
  {
    vid: 14,
    video_title: "What Is Intimacy?",
    video_url: "https://www.youtube.com/embed/z7GfH4t3Cb8",
  },
];

// STDs, pregnancy and contraception
const stdVideos = [
  {
    vid: 1,
    video_title: "Condoms: How To Use Them Effectively",
    video_url: "https://www.youtube.com/embed/oaLdNErJ-Fk",
  },
  {
    vid: 2,
    video_title: "What should you do if you've had unprotected sex?",
    video_url: "https://www.youtube.com/embed/48zdZ6x3SK4",
  },
  {
    vid: 3,
    video_title: "What Is HIV?",
    video_url: "https://www.youtube.com/embed/YxfOu_aTzH8",
  },
  {
    vid: 4,
    video_title: "The ABCs of STDs",
    video_url: "https://www.youtube.com/embed/oi6uzWxrFFY",
  },
  {
    vid: 5,
    video_title: "Birth Control Basics: Condoms, The Pill & Patch",
    video_url: "https://www.youtube.com/embed/50vmQzjRkuk",
  },
  {
    vid: 6,
    video_title: "Where Do Babies Come From?",
    video_url: "https://www.youtube.com/embed/vudZePDq8Bw",
  },
  {
    vid: 7,
    video_title: "Pregnancy and Reproduction Explained",
    video_url: "https://www.youtube.com/embed/OejdOS4IqeE",
  },
  {
    vid: 8,
    video_title: "What Is Emergency Contraception? (The Morning After Pill)",
    video_url: "https://www.youtube.com/embed/Z1LTban3Z84",
  },
  {
    vid: 9,
    video_title: "What Are The Stages Of Pregnancy?",
    video_url: "https://www.youtube.com/embed/5X0--KRfSpU",
  },
  {
    vid: 10,
    video_title: "PrEp and Pep: HIV Prevention",
    video_url: "https://www.youtube.com/embed/SOLV5ep6B-I",
  },
  {
    vid: 11,
    video_title: "So, You Think You're Pregnant",
    video_url: "https://www.youtube.com/embed/qs4do1DwMrI",
  },
  {
    vid: 12,
    video_title: "Does Pulling Out Prevent Pregnancy?",
    video_url: "https://www.youtube.com/embed/pWhDXhhTu8M",
  },
  {
    vid: 13,
    video_title: "Long-Acting Contraception Explained",
    video_url: "https://www.youtube.com/embed/VCHyEzIWMMI",
  },
  {
    vid: 14,
    video_title: "STD Prevention Beyond Condoms",
    video_url: "https://www.youtube.com/embed/41cFmDTABJY",
  },
];

// Understanding Emotions
const emotionsVideos = [
  {
    vid: 1,
    video_title: "What Are The Differences Between Behavior And Attraction?",
    video_url: "https://www.youtube.com/embed/pABtdwznm0c",
  },
  {
    vid: 2,
    video_title: "Feeling Depressed, Happy and Other Emotions",
    video_url: "https://www.youtube.com/embed/mAPLTaRM48Y",
  },
  {
    vid: 3,
    video_title: "Depression and Anxiety",
    video_url: "https://www.youtube.com/embed/wl9U8cDqv9c",
  },
  {
    vid: 4,
    video_title: "What Is Social Anxiety?",
    video_url: "https://www.youtube.com/embed/-Gjn9N5vfmc",
  },
  {
    vid: 5,
    video_title: "Sad and Happy: Feelings Happen",
    video_url: "https://www.youtube.com/embed/Ocj0gyZwL5Y",
  },
  {
    vid: 6,
    video_title: "Tips for Loving Yourself and Others",
    video_url: "https://www.youtube.com/embed/ru3vNDzmjX4",
  },
  {
    vid: 7,
    video_title: "Puberty: Feeling Depressed, Happy and Other Emotions",
    video_url: "https://www.youtube.com/embed/mAPLTaRM48Y",
  },
  {
    vid: 8,
    video_title: "How to Be a Good Listener",
    video_url: "https://www.youtube.com/embed/RVqjzzfCEk0",
  },
  {
    vid: 9,
    video_title: "Active Listening: How To Communicate Effectively",
    video_url: "https://www.youtube.com/embed/BW82k7lwI_U",
  },
  {
    vid: 10,
    video_title: "Fighting Fair: How Do You Resolve Conflict?",
    video_url: "https://www.youtube.com/embed/gu8gSuF_lvw",
  },
  {
    vid: 11,
    video_title: "How to Talk to Girls, Boys and Everyone in Between",
    video_url: "https://www.youtube.com/embed/9_0IJRaaXkM",
  },
];

// Violence and Safety
const violenceVideos = [
  {
    vid: 1,
    video_title: "What Is Sexual Assault?",
    video_url: "https://www.youtube.com/embed/w8gslq85Xsc",
  },
  {
    vid: 2,
    video_title: "Being Safe on the Internet",
    video_url: "https://www.youtube.com/embed/HxySrSbSY7o",
  },
  {
    vid: 3,
    video_title: "Being Safe Online",
    video_url: "https://www.youtube.com/embed/MB5VDIebMd8",
  },
  {
    vid: 4,
    video_title: "What Is Bullying?",
    video_url: "https://www.youtube.com/embed/KQZ9hDDz704",
  },
  {
    vid: 5,
    video_title: "Teasing: Not Just Harmless Fun",
    video_url: "https://www.youtube.com/embed/nKKDsIuMaKU",
  },
  {
    vid: 6,
    video_title:
      "Sexual Assault, Consent and Sexual Harassment: What's The Difference?",
    video_url: "https://www.youtube.com/embed/4qWt2qZ59w8",
  },
  {
    vid: 7,
    video_title: "Sexual Abuse Can Happen to Anyone",
    video_url: "https://www.youtube.com/embed/STyNOVjgxcM",
  },
  {
    vid: 8,
    video_title: "What Is Sexual Assault?",
    video_url: "https://www.youtube.com/embed/Y_yJZ9G-tt4",
  },
  {
    vid: 9,
    video_title: "Self Harm And Cutting",
    video_url: "https://www.youtube.com/embed/YK1Gch1CwI4",
  },
  {
    vid: 10,
    video_title: "Bullying: How To Safely Help Someone",
    video_url: "https://www.youtube.com/embed/LnQ1MdP-Qxc",
  },
  {
    vid: 11,
    video_title: "Intimate Partner Violence",
    video_url: "https://www.youtube.com/embed/vK3RhRwMwIg",
  },
  {
    vid: 12,
    video_title: "What Is Sexual Harassment?",
    video_url: "https://www.youtube.com/embed/HKk-pbeW3ic",
  },
  {
    vid: 13,
    video_title: "Sexting: What Should You Do?",
    video_url: "https://www.youtube.com/embed/RWxAimnKupE",
  },
];

// Life-skills development
const lifeSkillsVideos = [
  {
    vid: 1,
    video_title: "How to Be a Good Listener",
    video_url: "https://www.youtube.com/embed/RVqjzzfCEk0",
  },
  {
    vid: 2,
    video_title: "Active Listening: How To Communicate Effectively",
    video_url: "https://www.youtube.com/embed/BW82k7lwI_U",
  },
  {
    vid: 3,
    video_title: "Fighting Fair: How Do You Resolve Conflict?",
    video_url: "https://www.youtube.com/embed/gu8gSuF_lvw",
  },
  {
    vid: 4,
    video_title: "How to Talk to Girls, Boys and Everyone in Between",
    video_url: "https://www.youtube.com/embed/9_0IJRaaXkM",
  },
  {
    vid: 5,
    video_title: "What Are The Differences Between Behavior And Attraction?",
    video_url: "https://www.youtube.com/embed/pABtdwznm0c",
  },
  {
    vid: 6,
    video_title: "Feeling Depressed, Happy and Other Emotions",
    video_url: "https://www.youtube.com/embed/mAPLTaRM48Y",
  },
  {
    vid: 7,
    video_title: "Depression and Anxiety",
    video_url: "https://www.youtube.com/embed/wl9U8cDqv9c",
  },
  {
    vid: 8,
    video_title: "What Is Social Anxiety?",
    video_url: "https://www.youtube.com/embed/-Gjn9N5vfmc",
  },
  {
    vid: 9,
    video_title: "Sad and Happy: Feelings Happen",
    video_url: "https://www.youtube.com/embed/Ocj0gyZwL5Y",
  },
  {
    vid: 10,
    video_title: "Tips for Loving Yourself and Others",
    video_url: "https://www.youtube.com/embed/ru3vNDzmjX4",
  },
  {
    vid: 11,
    video_title: "Puberty: Feeling Depressed, Happy and Other Emotions",
    video_url: "https://www.youtube.com/embed/mAPLTaRM48Y",
  },
];

export default [
  {
    category: [CHATBOT_TOPICS.ABORTION_HEALTH_SERVICES],
    list: healthVideos,
  },
  {
    category: [CHATBOT_TOPICS.BODIES_AND_DEVELOPMENT],
    list: bodiesVideos,
  },
  {
    category: [CHATBOT_TOPICS.LIFE_SKILLS_DEVELOPMENT],
    list: lifeSkillsVideos,
  },
  {
    category: [CHATBOT_TOPICS.VIOLENCE_AND_SAFETY],
    list: violenceVideos,
  },
  {
    category: [CHATBOT_TOPICS.UNDERSTANDING_EMOTIONS],
    list: emotionsVideos,
  },
  {
    category: [CHATBOT_TOPICS.STD_PREGNANCY_CONTRACEPTION],
    list: stdVideos,
  },
  {
    category: [CHATBOT_TOPICS.MASTURBATION_AND_PLEASURE],
    list: mastVideos,
  },
  {
    category: [CHATBOT_TOPICS.MENSTRUATION],
    list: menstVideos,
  },
  {
    category: [CHATBOT_TOPICS.SEX_CONSENT_RELATIONSHIP],
    list: consentVideos,
  },
  {
    category: [CHATBOT_TOPICS.GENDER_AND_SEXUALITY],
    list: genderVideos,
  },
  {
    category: [CHATBOT_TOPICS.PARENT_GUIDE],
    list: parentsVideos,
  },
  {
    category: [CHATBOT_TOPICS.SYMPTOMS],
    list: [], // to be added
  },
];
