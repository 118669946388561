/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import tippy from "tippy.js";
import Swal from "sweetalert2";
import Aos from "aos";
import {
  FaChevronCircleRight,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { BiSend } from "react-icons/bi";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { addMessage, removeMessage } from "../../actions/chatActions";
import { TOGGLE_DROPDOWN, USER_INFO_RESET, USER_INFO } from "../../constant";
import { getContentByCategory, getTime } from "../../Utils/util";
import { VideoList } from "./VideoList";
import ChoiceQuestion from "./ChoiceQuestion";
import InputQuestion from "./InputQuestion";
import SayMore from "./SayMore";
import BotService from "../../Services/Bot";
import BotLogo from "../../Assets/Chatbot/BotLogo_blankbg.png";
// import FeedbackImage from "../../Assets/Chatbot/feedback1.png";
import sounds from "../../Assets/Sounds/sounds";
import CountryQuestion from "./CountryQuestion";
import { ProductList } from "./ProductList";
import AnalyticsService from "../../Services/Analytics";
import SearchBar from "../../Components/SearchBar";
import {
  ENTERPRISE_TYPES,
  SCHOOLS_USER_AGE_TYPES,
  USER_AGE_TYPES,
} from "../../types/chatbot";
import { FEEDBACK_URL, QUIZ_URL } from "../../Utils/constants";
import chatbotContents from "../../Utils/chatbotContents";
import "tippy.js/dist/tippy.css";
import "./style.css";
import "./mobile-responsive.css";

const ChatBot = (props) => {
  const location = useLocation();

  const enterpriseCategory =
    new URLSearchParams(location.search).get("enterprise") || "SWS";

  const { t } = useTranslation();

  const userInput = useRef("");

  const [list, setList] = useState([]);
  const [, setSearchTerm] = useState("");
  const [toggleTab, setToggleTab] = useState("tab2");
  const [showFeedback, setShowFeedback] = useState(0);
  const [showExtras, setShowExtras] = useState("videos");
  const [suggestedQues, setSuggestedQues] = useState(false);

  const suggested_question_by_age = [
    {
      age: USER_AGE_TYPES.age_below_13,
      question: [
        "What do I do if I'm being bullied?",
        "What is puberty and what to expect?",
        "What is menstruation?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_13_to_15,
      question: [
        "What do I do if I'm being bullied?",
        "What is menstruation?",
        "How do I know if I am gay?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_16_to_18,
      question: [
        "What do I do if I'm being bullied?",
        "What is menstruation?",
        "How do I know if I am gay?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_19_to_21,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "Who should I talk to if I am queer?",
        "How do I know if I am ready to have sex?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_22_to_25,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "How do I know if I am ready to have sex?",
        "What is birth control? How do I get birth control?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_26_to_30,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "What is birth control? How do I get birth control?",
        "What should I do if I am pregnant?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_31_to_35,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "What is birth control? How do I get birth control?",
        "What should I do if I am pregnant?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_36_to_40,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "What is birth control? How do I get birth control?",
        "What should I do if I am pregnant?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_41_to_45,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "What is birth control? How do I get birth control?",
        "What should I do if I am pregnant?",
      ],
    },
    {
      age: USER_AGE_TYPES.age_above_45,
      question: [
        "How do I know if I'm in an abusive relationship?",
        "What is birth control? How do I get birth control?",
        "What should I do if I am pregnant?",
      ],
    },
  ];
  const infoAboutGender = [
    {
      title: "WHAT DO THESE MEAN?",
      body: "These options include different gender identities and expressions. Your gender identity is how you feel inside and how you express those feelings.",
    },
    {
      title: "Boy/Man",
      body: "Someone who identifies as male. You can identify as a boy/man and also identify as cisgender or transgender",
    },
    {
      title: "Cisgender",
      body: "When a person's gender identity is generally the same as the sex they were assigned at birth.",
    },
    {
      title: "Genderfluid",
      body: "Gender fluidity is the sense that one's gender identity is not set in stone and that it can change over time or even from day to day.",
    },
    {
      title: "Girl/Woman",
      body: "Someone who identifies as female. You can identify as a girl/woman and also identify as cisgender or transgender.",
    },
    {
      title: "Intersex",
      body: "When someone is born with a sexual biology that is outside the strict boxes of “male” and “female,” because of their body parts (genitals or internal organs), chromosomes, and/or hormones.",
    },
    {
      title: "Nonbinary",
      body: "When a person's gender expression doesn't fit inside traditional male or female categories (sometimes called the gender binary).",
    },
    {
      title: "Questioning",
      body: "It's totally okay to not be sure! Only you know how to express your gender identity and you don't need to know right now.",
    },
    {
      title: "Trans-gender",
      body: "When a person's gender identity is different from the sex they were assigned at birth.",
    },
    {
      // title: "NONE OF THESE FEEL RIGHT FOR YOU?",
      title: "Something else",
      body: "That's totally ok! There are a lot of ways to identify, and all of them are valid. You don't *have* to fit into any of the above categories. You can always select something else, if you'd like.",
    },
  ];

  const scrollDown = () => {
    if (document.getElementById("interface-container")) {
      document.getElementById("interface-container").scrollTo({
        top: document.getElementById("interface-container").scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const ReportBlanket = (props) => {
    const sendReport = async (uid, question) => {
      setReported(true);
      await BotService.reportQuestion(uid, question, enterpriseCategory);
      Swal.fire("Great!", "Thank you for submitting the question.", "success");
    };

    const [reported, setReported] = useState(false);

    return (
      <>
        <div
          className="report-question-button"
          onClick={async () => {
            if (!reported) {
              await AnalyticsService.gaEvent(
                "Chatbot",
                `question submitted`,
                `${props.question}`,
                1,
              );
              sendReport(props.uid, props.question);
              new Audio(sounds.graceful).play();
            } else {
              new Audio(sounds.alertGesture).play();
              Swal.fire({
                icon: "info",
                title: "Hmmm...Sorry",
                text: "The Question is already been reported!",
              });
            }
          }}
        >
          SUBMIT THIS QUESTION
        </div>
      </>
    );
  };

  const askQuestionToBot = async (question) => {
    let answer = "";
    let qid = "";
    let asked_question = question;
    let found_question = "";
    try {
      props.startProcessing();
      const res = await BotService.getQueryByQuestion(
        question,
        props.chatStore?.tempUser?.age,
        props.chatStore?.tempUser?.gender,
        props.chatStore?.tempUser?.race || ["Something else"],
        enterpriseCategory,
      );
      props.stopProcessing();
      // console.log("Response: ", res);
      // const count = typeof res.data === 'object' ? res.data?.length : 0
      // if (count && count > 0) {
      //   console.log("\nRetrieved Count:" + count);
      //   if (count === 1) {
      // unique question found!
      qid = "ai-generated";
      answer = res.data.answer;
      found_question = "ai-generated";
      setShowExtras("videos");
      props.addBotMessage(answer);
      await AnalyticsService.markQuestion({
        question_id: qid,
        question_asked: asked_question,
        question_found: found_question,
        question_answered: answer,
        cuid: props.chatStore?.tempUser?.cuid,
        asked_by: props.chatStore?.tempUser?.name,
      });
      await AnalyticsService.updateRCount(qid);
      new Audio(sounds.graceful).play();
      // props.addKnowMore(res.data[0]); // old way to show additional content from db
      const content = getContentByCategory(
        chatbotContents,
        res.data.category,
        enterpriseCategory,
      );
      // console.log(res.data[0].category);
      // console.log(content);
      props.addKnowMore(content);
      setShowFeedback(showFeedback + 1);
      //   } else {
      //     console.log("More than one from \n", res.data);
      //     found_question = res.data.map((v) => v.question);
      //     found_question = found_question.slice(0, 4);
      //     props.addChoiceQuestion(found_question);
      //     await AnalyticsService.markQuestion({
      //       question_id: "XXX",
      //       question_asked: asked_question,
      //       question_found: found_question?.slice(0, 4)?.join(", "),
      //       question_answered: "multiple found",
      //       cuid: props.chatStore?.tempUser?.cuid,
      //       asked_by: props.chatStore?.tempUser?.name,
      //     });
      //   }
      // } else {
      //   answer =
      //     "That is a brilliant question! I shall scrounge behind my knowledge wardrobe for a bit and get back to you with the perfect answer.";
      //   const reply = question + "\n-----------------------------\n" + answer;
      //   props.addBotMessage(reply);
      //   await AnalyticsService.markQuestion({
      //     question_id: "XXX",
      //     question_asked: asked_question,
      //     question_found: "",
      //     question_answered: "Not found",
      //     cuid: props.chatStore?.tempUser?.cuid,
      //     asked_by: props.chatStore?.tempUser?.name,
      //   });
      // }
    } catch (error) {
      console.error(error);
    }
  };
  const getCuid = async (cb) => {
    const payload = {
      user_name: props.chatStore.tempUser.name,
      user_age: props.chatStore.tempUser.age,
      user_gender: props.chatStore.tempUser.gender || [],
      user_race: props.chatStore.tempUser.race || ["Something else"],
      user_country: props.chatStore.tempUser.country,
      questions_List: [],
      enterprise_name: enterpriseCategory,
    };
    const res = await BotService.saveChatUser(payload);
    const uid = res.data?.data?.cuid;
    payload.user_id = uid;
    const event = await BotService.fireFBEvent(payload);
    console.log(event);
    // console.log(uid);
    cb(uid, props.chatStore.tempUser);
  };

  // const showEnterpriseOptions = async () => {
  //   const { value: enterprise } = await Swal.fire({
  //     title: "Select color",
  //     input: "radio",
  //     inputOptions: {
  //       SWS: "SWS",
  //       Schools: "Schools",
  //       Mansuri: "Mansuri",
  //     },
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "You need to choose something!";
  //       }
  //     },
  //   });
  //   if (enterprise) {
  //     window.open("/Chatbot?enterprise=" + enterprise, "_self");
  //   }
  // };

  // useEffect(() => {
  //   if (location) {
  //     if (!enterpriseCategory) {
  //       console.log("Param is missing! Pop up option for selecting enterprise");

  //       showEnterpriseOptions();
  //     }
  //   }
  // }, [location]);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    if (props.chatStore?.tempUser?.gender?.length > 0) {
      props.addRace(props.chatStore?.tempUser);
      getCuid(props.addChatUserId);
      // props.addChatUserId(cuid, props.chatStore.tempUser);

      let instance;
      setTimeout(() => {
        instance = tippy(document.querySelector("#suggested-questions"), {
          placement: "right",
          content: "Suggested Questions",
          theme: "mae-tippy",
        });
        instance.show();
      }, 2000);
      setTimeout(() => {
        instance.destroy();
        instance = tippy(document.querySelector("#bot-chat-menu"), {
          content: "Categories",
          offset: [0, 24],
          theme: "mae-tippy",
        });
        instance.show();
      }, 5000);
      setTimeout(() => {
        instance.destroy();
        instance = tippy(document.querySelector("#bot-chat-input"), {
          placement: "top",
          content: "Ask me a question",
          offset: [0, 24],
          theme: "mae-tippy",
        });
        instance.show();
        tippy(document.querySelector("#bot-chat-menu"), {
          placement: "top",
          content: "Categories",
          offset: [0, 24],
          theme: "mae-tippy",
        });
      }, 8000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatStore.tempUser.gender.length]);
  // dropdown code

  useEffect(() => {
    const groupByKey = (array, key) => {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    };
    if (list.length === 0) {
      const getList = async () => {
        let questionBank = await BotService.getAllQuestions(enterpriseCategory);
        if (questionBank.data && questionBank.data.length) {
          const qaGroups = groupByKey(questionBank.data, "category");
          let finalList = [];
          for (const category in qaGroups) {
            finalList.push({
              title: category,
              data: qaGroups[category],
            });
          }
          setList(finalList);
          // console.log(finalList);
        }
      };
      getList();
    }
  }, [list.length]);

  useEffect(() => {
    if (props.chatStore.tempUser.age && !suggestedQues) {
      const getSuggested = suggested_question_by_age.filter(
        (x) => x.age === props.chatStore.tempUser.age,
      );
      props.addSuggestMessage(getSuggested[0].question);
      setSuggestedQues(true);
    }
  }, [props.chatStore.tempUser.age]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollDown();
    }, 300);
    return () => clearTimeout(timeout);
  }, [props.chatStore.chats, props.chatStore.chats.length]);

  useEffect(() => {
    if (showFeedback >= 0 && showFeedback <= 2) {
      if (showFeedback === 1) {
        setTimeout(() => {
          props.addFeedback();
        }, 25000);
        setShowFeedback(showFeedback + 1);
      }
    }
  }, [showFeedback]);

  if (!props.chatStore.tempUser.say1) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mae - Your AI-driven Sexual Health Platform</title>
          <meta
            name="title"
            content="Mae - Your AI-driven Sexual Health Platform"
          />
          <meta
            name="description"
            content="Your one-stop solution to all sexual and reproductive health needs. Leveraging artificial intelligence to provide anonymous answers to your awkward questions. Medically accurate,non-judgmental, and gender-inclusive."
          />
          <meta
            name="keywords"
            content="Mae, Chatbot, Ask away all your doubts and Expert advice, Menstrual hygiene products, sexual wellness products, sexual health resources, Women's Health, Femtech"
          />
        </Helmet>
        <SayMore
          keyName="say1"
          say={t("chatbot_greet_body")}
          // say="Thank you for believing that I can help you out with your queries. <br/>Before we proceed, I would like to get to know you a bit."
        />
      </>
    );
  }

  if (!props.chatStore.tempUser.country) {
    return (
      <CountryQuestion
        inputName="country"
        inputQuestion={t("chatbot_country_question")}
        infoAbout={t("chatbot_country_subtitle")}
      />
    );
  }

  if (!props.chatStore.tempUser.name) {
    return (
      <InputQuestion
        inputName="name"
        inputQuestion={t("chatbot_name_question")}
      />
    );
  }

  if (!props.chatStore.tempUser.age) {
    return (
      <ChoiceQuestion
        width={"100%"}
        selectedChoice=""
        choiceName={"age"}
        choiceQuestion={t("chatbot_age_question")}
        choiceAnswers={
          enterpriseCategory === ENTERPRISE_TYPES.SCHOOLS
            ? Object.values(SCHOOLS_USER_AGE_TYPES)
            : Object.values(USER_AGE_TYPES)
        }
        // choiceAnswers={t("chatbot_age_answers", {
        //   returnObjects: true,
        //   lng: "en",
        // })}
      />
    );
  }

  if (!props.chatStore.tempUser.gender) {
    return (
      <ChoiceQuestion
        width={"100%"}
        multiple={true}
        selectedChoice=""
        infoAbout={infoAboutGender}
        choiceName={"gender"}
        choiceQuestion={t("chatbot_gender_question")}
        choiceAnswers={t("chatbot_gender_answers", {
          returnObjects: true,
          lng: "en",
        })}
      />
    );
  }
  // if (!props.chatStore.tempUser.race) {
  //   new Audio(sounds.chinUp).play();
  //   return (
  //     <ChoiceQuestion
  //       width={"100%"}
  //       multiple={true}
  //       infoAbout={[]}
  //       selectedChoice=""
  //       choiceName={"race"}
  //       choiceQuestion={`${t("hi")} ${props.chatStore.tempUser.name}!  \n${t(
  //         "chatbot_race_question"
  //       )}`}
  //       choiceAnswers={t("chatbot_race_answers", {
  //         returnObjects: true,
  //         lng: "en",
  //       })}
  //     />
  //   );
  // }
  return (
    <div className="bot-container" style={{ backgroundColor: "#FFF" }}>
      <div
        className="bot-chat-header"
        style={{ display: props.chatStore.bottomDropdown ? "none" : "flex" }}
      >
        <div
          title={t("go_to_home")}
          className="bot-chat-icon"
          onClick={async () => {
            await AnalyticsService.gaEvent(
              "Chatbot",
              `button clicked`,
              `clicked on logo`,
              1,
            );
            window.open("/", "_self");
          }}
        >
          {/* <FaHome size={24} /> */}
          <img src={BotLogo} alt="Mae the chatbot" className="bot-logo-sm" />
          {/* <span>{process.env.REACT_APP_BACKEND_URL}</span> */}
        </div>
        <div
          title="Enter your name again"
          className="bot-chat-new-user"
          onClick={async () => {
            await AnalyticsService.gaEvent("Chatbot", `quiz link`, QUIZ_URL, 1);
            window.open(QUIZ_URL, "_blank");
          }}
        >
          {/* <img src={FeedbackImage} width="30" alt="feedback_link" /> */}
          QUIZ
        </div>
      </div>
      <div
        id="interface-container"
        className="bot-chat-interface-container"
        style={{ display: props.chatStore.bottomDropdown ? "none" : "flex" }}
      >
        <div className="bot-chat-interface">
          {props.chatStore.chats.map((v, i) => {
            if (v.origin === "bot") {
              if (v.message.includes("That is a brilliant question!")) {
                return (
                  <div key={i} className="chat-message bot-message">
                    {v.message?.split("\n-----------------------------\n")[1]}
                    <ReportBlanket
                      uid={props.chatStore?.tempUser?.name}
                      question={v.message?.split("\n")[0]}
                    />
                    <div className="bot-time">{v.time}</div>
                  </div>
                );
              } else {
                return (
                  <div key={i} className="chat-message bot-message">
                    {v.message}
                    <div className="bot-time">{v.time}</div>
                  </div>
                );
              }
            } else if (v.origin === "bot_loading") {
              return (
                <div className="chat-message bot-message bot_loading">
                  . <span className="waitingForConnection">. </span>
                  <span className="waitingForConnectionDelay">.</span>
                </div>
              );
            } else if (v.origin === "suggest") {
              return (
                <div
                  key={i}
                  id="suggested-questions"
                  className="bot-choices suggested-questions"
                  style={{ width: "max-content" }}
                >
                  {v.message.map((question, i) => (
                    <div
                      key={i}
                      className="bot-choice"
                      onClick={() => {
                        const wordsLength = question?.split(" ").length;
                        if (wordsLength > 2) {
                          props.addUserMessage(question);
                          askQuestionToBot(question);
                          AnalyticsService.gaEvent(
                            "Chatbot",
                            `question selected from suggestion`,
                            `${question}`,
                            1,
                          );
                        } else {
                          new Audio(sounds.alertGesture).play();
                          props.addBotMessage(
                            "Kindly phrase your questions with more than 2 words",
                          );
                        }
                      }}
                    >
                      <div>{question} &nbsp;&nbsp;&nbsp;&nbsp;</div>
                      <div>
                        <FaChevronCircleRight size={24} />
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else if (v.origin === "feedback") {
              return (
                <div
                  key={i}
                  className="chat-message bot-message feedback-message"
                >
                  {t("chatbot_feedback_message")}
                  <a
                    href={FEEDBACK_URL}
                    className="feedback-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("sure")}
                  </a>
                </div>
              );
            } else if (v.origin === "user") {
              return (
                <div key={i} className="chat-message user-message">
                  {v.message}
                  <div className="user-time">{v.time}</div>
                </div>
              );
            } else if (v.origin === "show_videos_button") {
              const tabSounds = sounds.stairs;
              return (
                <div
                  key={i}
                  className="chat-message bot-message bg-remove extend-max-width"
                >
                  <div className="chat-buttons-list">
                    <div
                      className={`chat-message-button ${
                        showExtras === "videos" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setShowExtras("videos");
                        AnalyticsService.gaEvent(
                          "Chatbot-services",
                          `service-chosen`,
                          `videos`,
                          1,
                        );
                        new Audio(tabSounds).play();
                        scrollDown();
                      }}
                    >
                      VIDEOS
                    </div>
                    <div
                      className={`chat-message-button ${
                        showExtras === "products" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setShowExtras("products");
                        AnalyticsService.gaEvent(
                          "Chatbot-services",
                          `service-chosen`,
                          `products`,
                          1,
                        );
                        new Audio(tabSounds).play();
                        scrollDown();
                      }}
                    >
                      PRODUCTS
                    </div>
                    <div
                      className={`chat-message-button ${
                        showExtras === "consults" ? "selected" : ""
                      } ${
                        props.chatStore?.tempUser?.age ===
                          USER_AGE_TYPES.age_below_13 ||
                        props.chatStore?.tempUser?.age ===
                          USER_AGE_TYPES.age_13_to_15
                          ? "hideServiceOption"
                          : ""
                      }`}
                      onClick={() => {
                        setShowExtras("consults");
                        AnalyticsService.gaEvent(
                          "Chatbot-services",
                          `service-chosen`,
                          `consults`,
                          1,
                        );
                        new Audio(tabSounds).play();
                        scrollDown();
                      }}
                    >
                      EXPERTS
                    </div>
                    <div
                      className={`chat-message-button ${
                        showExtras === "resources" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setShowExtras("resources");
                        AnalyticsService.gaEvent(
                          "Chatbot-services",
                          `service-chosen`,
                          `resources`,
                          1,
                        );
                        new Audio(tabSounds).play();
                        scrollDown();
                      }}
                    >
                      RESOURCES
                    </div>
                    {/* <div
                      className={`chat-message-button ${
                        showExtras === "followups" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setShowExtras("followups");
                        AnalyticsService.gaEvent(
                          "Chatbot-services",
                          `service-chosen`,
                          `followups`,
                          1
                        );
                        scrollDown();
                      }}
                    >
                      FOLLOW-UP
                    </div> */}
                  </div>
                  {showExtras === "videos" && (
                    <div className="chat-message bg-remove">
                      <VideoList
                        data={v.videos}
                        category={"video"}
                        buttonTitle={""}
                      />
                    </div>
                  )}
                  {showExtras === "products" && (
                    <div className="bot-choices-2">
                      <ProductList
                        data={v.products}
                        category={"product"}
                        buttonTitle={"SHOP NOW >"}
                      />
                    </div>
                  )}
                  {showExtras === "consults" && (
                    <div className="bot-choices-2">
                      <ProductList
                        data={v.consults}
                        category={"consults"}
                        buttonTitle={"BOOK NOW >"}
                      />
                    </div>
                  )}
                  {showExtras === "resources" && (
                    <div className="bot-choices-2">
                      <ProductList
                        data={v.resources}
                        category={"resources"}
                        buttonTitle={"KNOW MORE >"}
                      />
                    </div>
                  )}
                  {/* {showExtras === "followups" && (
                    <div
                      className="bot-choices hs-mod"
                      style={{ minWidth: "88%" }}
                    >
                      {v.followups.length > 0 &&
                        v.followups.map((el, i) => (
                          <div
                            key={i}
                            className="bot-choice"
                            onClick={() => {
                              const wordsLength =
                                el.questions?.split(" ").length;
                              if (wordsLength > 2) {
                                props.addUserMessage(el.questions);
                                askQuestionToBot(el.questions);
                                AnalyticsService.gaEvent(
                                  `chatbot-service follow-up`,
                                  `question selected`,
                                  `${el.questions}`,
                                  1
                                );
                                scrollDown();
                              } else {
                                new Audio(sounds.alertGesture).play();
                                props.addBotMessage(
                                  "Kindly phrase your questions with more than 2 words"
                                );
                              }
                            }}
                          >
                            <div>{el.questions} &nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <div>
                              <FaChevronCircleRight size={24} />
                            </div>
                          </div>
                        ))}
                    </div>
                  )} */}
                </div>
              );
            } else if (v.origin === "show_videos") {
              return (
                <div key={i} className="chat-message bg-remove">
                  <VideoList data={v.data} category={v.category} />
                </div>
              );
            } else if (v.origin === "choice_questions") {
              return (
                <div key={i} className="chat-message bot-message bot-choices">
                  <p>Did you mean one of these?</p>
                  {v.questions.length > 0 &&
                    v.questions?.map((question, i) => (
                      <div
                        key={i}
                        className="bot-choice fs-16"
                        onClick={() => {
                          const wordsLength = question?.split(" ").length;
                          if (wordsLength > 2) {
                            props.addUserMessage(question);
                            askQuestionToBot(question);
                            AnalyticsService.gaEvent(
                              "Chatbot",
                              `question selected`,
                              `${question}`,
                              1,
                            );
                          } else {
                            new Audio(sounds.alertGesture).play();
                            props.addBotMessage(
                              "Kindly phrase your questions with more than 2 words",
                            );
                          }
                        }}
                      >
                        <div>{question} &nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div>
                          <FaChevronCircleRight size={24} />
                        </div>
                      </div>
                    ))}
                  <p>
                    If Your question doesn't exist in this list, can you
                    rephrase your question.
                  </p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <div
        className="bot-chat-bar"
        style={{ display: props.chatStore.bottomDropdown ? "none" : "flex" }}
      >
        <div
          id="bot-chat-menu"
          className="bot-chat-menu"
          onClick={() => {
            props.toggleDropdownDrawer();
            AnalyticsService.gaEvent(
              "Chatbot",
              `button clicked`,
              `opened the menu bar`,
              1,
            );
          }}
        >
          <FiMenu title="Menu" size={40} style={{ strokeWidth: 3 }} />
        </div>
        <div className="bot-chat-input">
          <input
            id="bot-chat-input"
            ref={userInput}
            type="text"
            placeholder="Ask me a question"
            onKeyDown={async (e) => {
              var regex = new RegExp("^[a-zA-Z0-9\\s]+$");
              if (!regex.test(e.key)) {
                e.preventDefault();
                Swal.fire(
                  "Warning",
                  "Special character (such as , . ? /) are not allowed.",
                  "warning",
                );
                return false;
              }
              if (e.key === "Enter") {
                const wordsLength = userInput.current.value?.split(" ").length;
                if (wordsLength > 2) {
                  let userValue = userInput.current.value;
                  if (props.chatStore.tempUser.country === "South Korea") {
                    // code for translating other lang to english over here
                  }
                  props.addUserMessage(userValue);
                  askQuestionToBot(userInput.current.value.trim());
                  AnalyticsService.setFirstDimension(
                    userInput.current.value.trim(),
                  );
                  userInput.current.value = "";
                  AnalyticsService.gaEvent(
                    "Chatbot",
                    "button clicked",
                    "Ask question to bot",
                    1,
                  );
                } else {
                  props.addBotMessage(
                    "Kindly phrase your questions with more than 2 words",
                  );
                }
              }
            }}
          />
        </div>
        <div
          className="bot-chat-send"
          onClick={() => {
            const wordsLength = userInput.current.value?.split(" ").length;
            if (wordsLength > 2) {
              props.addUserMessage(userInput.current.value);
              askQuestionToBot(userInput.current.value.trim());
              AnalyticsService.setFirstDimension(
                userInput.current.value.trim(),
              );
              userInput.current.value = "";
              AnalyticsService.gaEvent(
                "Chatbot",
                "button clicked",
                "Ask question to bot",
                1,
              );
            } else {
              props.addBotMessage(
                "Kindly phrase your questions with more than 2 words",
              );
            }
          }}
        >
          <span className="hide-sm">Send</span> <BiSend size={24} />
        </div>
      </div>
      {/* BOTTOM DRAWER */}
      <div
        className="bot-list-dropdown"
        style={{ display: props.chatStore.bottomDropdown ? "flex" : "none" }}
      >
        <FaChevronUp
          size={40}
          className="icon-top"
          onClick={() => props.toggleDropdownDrawer()}
        />
        <div className="bot-search-bar">
          <SearchBar searchTerm={setSearchTerm} />
        </div>
        <div className="bot-list-headers">
          <div
            className={
              "bot-list-headers-item " + (toggleTab === "tab2" ? "active" : "")
            }
            onClick={() => {
              AnalyticsService.gaEvent(
                "Chatbot",
                `question bank selected`,
                `categories list`,
                1,
              );
              setToggleTab("tab2");
            }}
          >
            Categories
          </div>
          <div
            className={
              "bot-list-headers-item " + (toggleTab === "tab1" ? "active" : "")
            }
            onClick={() => {
              AnalyticsService.gaEvent(
                "Chatbot",
                `question bank selected`,
                `featured list`,
                1,
              );
              setToggleTab("tab1");
            }}
          >
            Featured Questions
          </div>
        </div>
        <div className="bot-list-body">
          <div
            className={
              "bot-list-body-item " + (toggleTab === "tab2" ? "active" : "")
            }
          >
            <Accordion defaultActiveKey="0">
              {list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <Card key={index}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={"name" + index}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {item.title}
                        <FaChevronDown />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={"name" + index}>
                        <Card.Body>
                          {item.data.slice(0, 40).map((v, i) => {
                            return (
                              <p
                                key={i}
                                onClick={() => {
                                  props.addUserMessage(v.question);
                                  askQuestionToBot(v.question);
                                  props.toggleDropdownDrawer();
                                  AnalyticsService.gaEvent(
                                    "Chatbot-category",
                                    `question asked`,
                                    `${item.title} ${v.question}`,
                                    1,
                                  );
                                }}
                                className="clickQuestion"
                              >
                                {v.question}
                              </p>
                            );
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
            </Accordion>
            <br />
            <br />
          </div>
          <div
            className={
              "bot-list-body-item " + (toggleTab === "tab1" ? "active" : "")
            }
          >
            {[
              "Is my vaginal discharge normal?",
              "What is menstruation?",
              "What is the first symptom of pregnancy?",
              "What's the Plan B morning after pill?",
              "How do I deal with someone pressuring me to have sex?",
              "Is it normal to masturbate?",
              "What is consent?",
              "What is coming out?",
              "How do I get over a breakup?",
            ].map((question, i) => {
              return (
                <p
                  key={i}
                  onClick={() => {
                    props.addUserMessage(question);
                    askQuestionToBot(question);
                    props.toggleDropdownDrawer();
                    AnalyticsService.gaEvent(
                      "Chatbot-featured",
                      `question asked`,
                      `${question}`,
                      1,
                    );
                  }}
                  className="clickQuestion"
                >
                  {question}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatStore: state.ChatReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () =>
      dispatch({
        type: USER_INFO_RESET,
      }),
    toggleDropdownDrawer: () =>
      dispatch({
        type: TOGGLE_DROPDOWN,
      }),
    addUserMessage: (message) =>
      dispatch(
        addMessage({
          origin: "user",
          message: message || "",
          time: `${getTime()}`,
        }),
      ),
    startProcessing: () =>
      dispatch(
        addMessage({
          origin: "bot_loading",
        }),
      ),
    addBotMessage: (message, data) =>
      dispatch(
        addMessage({
          origin: "bot",
          message: message || "",
          time: `${getTime()}`,
        }),
      ),
    stopProcessing: () => dispatch(removeMessage()),
    addKnowMore: (data) => {
      dispatch(
        addMessage({
          origin: "show_videos_button",
          message: "",
          videos: data.linked_videos.list
            ? data.linked_videos.list.slice(0).sort(() => Math.random() - 0.5)
            : [],
          followups: data.linked_followups.list || [],
          products: data.linked_product.list
            ? data.linked_product.list.slice(0).sort(() => Math.random() - 0.5)
            : [],
          resources: data.linked_resources.list
            ? data.linked_resources.list
                .slice(0)
                .sort(() => Math.random() - 0.5)
            : [],
          consults: data.linked_consults.list || [],
          time: `${getTime()}`,
        }),
      );
    },
    addVideosList: (category) => {
      dispatch(
        addMessage({
          origin: "show_videos",
          category: category,
          data: [],
          time: `${getTime()}`,
        }),
      );
      // setTimeout(() => {
      //   if (document.getElementById("interface-container")) {
      //     document.getElementById("interface-container").scrollTo({
      //       top: document.getElementById("interface-container").scrollHeight,
      //       behavior: "smooth",
      //     });
      //   }
      // }, 1400);
    },
    addChoiceQuestion: (questions) =>
      dispatch(
        addMessage({
          origin: "choice_questions",
          questions,
          time: `${getTime()}`,
        }),
      ),
    addSuggestMessage: (message) =>
      dispatch(
        addMessage({
          origin: "suggest",
          message,
          time: `${getTime()}`,
        }),
      ),
    addChatUserId: (value, tempUser) =>
      dispatch({
        type: USER_INFO,
        payload: { ...tempUser, cuid: value },
      }),
    addFeedback: () =>
      dispatch(
        addMessage({
          origin: "feedback",
          message: "",
          time: "",
        }),
      ),
    addRace: (tempUser) =>
      dispatch({
        type: USER_INFO,
        payload: { ...tempUser, race: ["Something else"] },
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatBot);

ChatBot.whyDidYouRender = true;
