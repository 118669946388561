import juntos from "./juntos-607.mp3";
import graceful from "./graceful-558.mp3";
import pullout from "./pull-out-551.mp3";
import appointed from "./appointed-529.mp3";
import unconvinced from "./unconvinced-569.mp3";
import alertGesture from "./gesture-192.mp3";
import stairs from "./stairs-567.mp3";

export default {
  pullout,
  juntos,
  graceful,
  appointed,
  unconvinced,
  alertGesture,
  stairs,
};
