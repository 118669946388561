import React, { Component } from "react";
import { FaGoogle } from "react-icons/fa";
import GoogleLogin from "react-google-login";
import "../LoginModal/style.css";
import Axios from "axios";
import ToastUtil from "../../Services/ToastUtil";
import { logInUser, setShowModal } from "../../actions/loginActions";
import { connect } from "react-redux";

class GoogleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  runCode(u) {
    this.props.logInUser1(u);
  }
  responseGoogle(resp, GB_this) {
    // console.log("Google Credentials.. ");
    // console.log(resp);
    var profile = resp.profileObj;

    let formData = {
      name: {
        fname: profile.givenName,
        lanme: profile.familyName,
      },
      email: profile.email,
      type: "google",
      image_url: profile.imageUrl,
    };
    Axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/socialLogin`,
      formData,
    )
      .then((response) => {
        // console.log(response.data);//testing..
        //set the same object in global state .. redux
        if (response.status === 200) {
          GB_this.props.dispatch(logInUser(response.data.user));
          // console.log("User", response.data);
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("email", formData.email);
          localStorage.setItem("type", formData.type);
          // console.log('Token:  ' + response.data.accessToken);
          GB_this.props.dispatch(setShowModal(false));
          ToastUtil.popUpMessage("Successfully Logged In!");
        } else {
          ToastUtil.popUpMessage("Kindly re-check your email or password!");
        }
      })
      .catch((error) => {
        console.log(error);
        ToastUtil.popUpMessage("Kindly re-check your email or password!");
      });

    alert("Logged in as " + profile.givenName);
  }
  failureGoogle() {
    console.log("log in failed..");
  }
  render() {
    return (
      <GoogleLogin
        clientId="661798000622-9n1l8pdk3cncg7m24sshktr5cgitgpr1.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            className="btn rounded-circle shadow text-red"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            {/* <button className="btn rounded-circle shadow text-red grayscale" onClick={renderProps.onClick} disabled={renderProps.disabled}> */}
            <FaGoogle />
          </button>
        )}
        disabled={false}
        onSuccess={(res) => this.responseGoogle(res, this)}
        onFailure={this.failureGoogle}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(GoogleButton);
