/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { USER_INFO, USER_INFO_RESET } from "../../constant";
import sounds from "../../Assets/Sounds/sounds";
import user from "../../Services/user";
import i18n from "../../Utils/translation";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "aos/dist/aos.css";

const CountryQuestion = (props) => {
  const { t } = useTranslation();

  const selectValue = useRef("");

  const options = [
    {
      key: "",
      value: t("chatbot_country_answers_select"),
    },
    {
      key: "India",
      value: t("chatbot_country_answers_india"),
    },
    {
      key: "South Korea",
      value: t("chatbot_country_answers_korea"),
    },
    {
      key: "Others",
      value: t("chatbot_country_answers_other"),
    },
  ];

  const [windowDimensions, setWindowDimensions] = useState(
    user.getWindowDimensions(),
  );

  const enterFunction = useCallback((e) => {
    // console.log("selected ", selectValue.current.value);
    if (e.key === "Enter") {
      props.addInput(
        props.inputName,
        selectValue.current.value,
        props.chatStore.tempUser,
      );
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(user.getWindowDimensions());
    }
    if (window) {
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", enterFunction, false);
    return () => {
      document.removeEventListener("keyup", enterFunction, false);
    };
  }, []);

  return (
    <div className="bot-container">
      <div className="bot-questions" data-aos="zoom-in">
        {props.inputQuestion}
      </div>
      <p></p>
      <div className="bot-input-container">
        <select
          ref={selectValue}
          className="bot-select"
          type="text"
          placeholder={"Enter " + props.inputName}
          onMouseDown={() => {
            if (selectValue.current.options.length > 5) {
              selectValue.current.size = 5;
            }
          }}
          onChange={() => {
            selectValue.current.blur();
            if (selectValue.current.value === "India") {
              i18n.changeLanguage("en");
            } else if (selectValue.current.value === "South Korea") {
              i18n.changeLanguage("kr");
            } else {
              i18n.changeLanguage("en");
            }
          }}
          onBlur={() => {
            selectValue.current.removeAttribute("size");
          }}
          data-aos="fade-in"
          data-aos-duration="200"
        >
          {options.map((option) => {
            return (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            );
          })}
        </select>
        <p style={{ color: "#D9D3F8", marginTop: 8 }}>{t("enter_for_next")}</p>
        {windowDimensions.width > 640 ? (
          <>
            <div
              className="bot-prev-step"
              onClick={() => {
                new Audio(sounds.unconvinced).play();
                props.goBack();
              }}
            >
              <FaChevronLeft size={80} />
            </div>
            <div
              className="bot-next-step"
              onClick={() =>
                props.addInput(
                  props.inputName,
                  selectValue.current.value,
                  props.chatStore.tempUser,
                )
              }
            >
              <FaChevronRight size={80} />
            </div>
          </>
        ) : (
          <div
            className="bot-next-button"
            onClick={() =>
              props.addInput(
                props.inputName,
                selectValue.current.value,
                props.chatStore.tempUser,
              )
            }
          >
            Next <FaChevronRight size={24} style={{ marginLeft: 12 }} />
          </div>
        )}
      </div>
      {props.infoAbout && props.infoAbout.length > 0 && (
        <>
          <div className="show-info">{props.infoAbout}</div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatStore: state.ChatReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () =>
      dispatch({
        type: USER_INFO_RESET,
      }),
    addInput: async (x, value, tempUser) => {
      if (value) {
        new Audio(sounds.unconvinced).play();
        dispatch({
          type: USER_INFO,
          payload: { ...tempUser, [x]: value },
        });
      } else {
        new Audio(sounds.alertGesture).play();
        Swal.fire({
          icon: "info",
          title: "Hey there!",
          text: `It seems like you forgot to tell me about your ${x}. Knowing you better helps me answer your questions accurately. 😊`,
        });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryQuestion);
