import Axios from "axios";

export default {
  getMembers: async (setMember) => {
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/team/find`,
      );
      setMember(res.data);
    } catch (error) {
      console.log("Something went wrong!", error);
      setMember([]);
    }
  },
  getMentors: async (setMentor) => {
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/mentor/find`,
      );
      setMentor(res.data);
    } catch (error) {
      console.log("Something went wrong!", error);
      setMentor([]);
    }
  },
};
