import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Card } from "react-bootstrap";
import Heading from "../Reusable/Heading";
import AboutUs from "../../Assets/Images/AboutUs.png";
// import images

export default () => {
  const { t } = useTranslation();

  let count = "01";
  let heading = t("about_us_title");
  let title = ``;
  let margin = "1vw 0 0 2.5vw";

  return (
    <React.Fragment>
      <div id="about-us" className="parent">
        <div className="image">
          <img src={AboutUs} alt="about us" />
        </div>
        <div>
          <Card
            className="card-aboutus"
            data-aos="fade-left"
            data-aos-anchor="#about-us"
            style={{
              height: "auto",
            }}
          >
            <Card.Title className="card-title">
              <Heading
                count={count}
                heading={heading}
                title={title}
                margin={margin}
                headingColor="#313131"
              />
            </Card.Title>
            <Card.Body
              style={{
                // fontSize: "10px",
                padding: "0 15%",
                marginTop: "-70px",
              }}
            >
              <p className="about-us-content">{t("about_us_body")}</p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

// transition: background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s;
//     transition-property: background, border, border-radius, box-shadow;
//     transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
//     transition-timing-function: ease, ease, ease, ease;
//     transition-delay: 0s, 0s, 0s, 0s;
