import React from "react";
import Axios from "axios";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaChevronDown } from "react-icons/fa";
import { logInUser, logOutUser } from "../../actions/loginActions";
import { redirectToSiteSelf, smoothScrollTo } from "../../Utils/util";
import company_logo from "../../Assets/Images/Company_Logo.png";
// import IndiaFlag from "../../Assets/Chatbot/IN.svg";
// import KoreaFlag from "../../Assets/Chatbot/KR.svg";
import AnalyticsService from "../../Services/Analytics";
import i18n from "../../Utils/translation.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { t } from "i18next";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.languages = {
      en: "English",
      kr: "Korean",
    };
    this.state = {
      showModal: false,
      refreshToken: localStorage.getItem("refreshToken"),
      showTranslationMenu: false,
      translation: "en",
    };
  }

  autoLogin = async () => {
    let mount = true;
    // console.log('autologin');
    if (this.props.login.isUserLogged === false && mount) {
      if (localStorage.getItem("email") && localStorage.getItem("password")) {
        const formData = {
          email: localStorage.getItem("email"),
          password: localStorage.getItem("password"),
        };
        await Axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          formData,
        )
          .then((response) => {
            if (response.status === 200) {
              // console.log('logged as ', response.data.user.name.fname);
              this.props.dispatch(logInUser(response.data.user));
              // console.log('logged:', this.props.login.isUserLogged);
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem("refreshToken", response.data.refreshToken);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("email", formData.email);
              localStorage.setItem("password", formData.password);
              // console.log('Token:  ' + response.data.accessToken);

              toast.dark("Logged in as " + response.data.user.name.fname, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.dark("Kindly re-check your email or password!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast.dark("Kindly re-check your email or password!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      console.log("false");
    }
  };

  handleLogout = (e) => {
    e.preventDefault();
    this.setState({ refreshToken: null }, () => localStorage.clear());
    this.props.dispatch(logOutUser());
    // authAxios
    //   .delete(`https://backend.standwespeak.com/users/logout`)
    //   .then((response) => {
    // console.log(response);
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  loginSuccess = (success) => {
    if (success)
      this.setState({ refreshToken: localStorage.getItem("refreshToken") });
  };

  handleScrollTo = (e) => {
    e.preventDefault();

    const el = document.getElementById("programs");
    el.scrollIntoView();
  };

  scrollToNewsletter = (e) => {
    e.preventDefault();
    let rn = document.getElementById("hiring");
    if (rn) {
      rn.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  scrollToContactMail = (e) => {
    e.preventDefault();
    let cm = document.getElementById("contact-mail");
    if (cm) {
      cm.scrollIntoView({ behavior: "smooth" });
    }
  };

  redirectTo = (v) => {
    var a = document.getElementById("hiring");
    var b = document.getElementById("contact-mail");

    if (v === 1) {
      if (a) {
        smoothScrollTo("hiring");
      } else {
        redirectToSiteSelf("/?hire");
      }
    } else if (v === 2) {
      if (b) {
        smoothScrollTo("contact-mail");
      } else {
        redirectToSiteSelf("/?mail");
      }
    }
  };

  // renderFlag = () => {
  //   if(this.state.translation === "kr") {
  //     return (
  //       <img
  //         src={KoreaFlag}
  //         alt={"South Korea"}
  //         className="cursorOnHover"
  //         />
  //     )
  //   } else {
  //     return (
  //       <img
  //         src={IndiaFlag}
  //         alt={"South Korea"}
  //         className="cursorOnHover"
  //       />
  //     )
  //   }
  // }

  // componentDidMount = async () => {
  //   // await this.autoLogin();
  //   console.log('logged:', this.props.login.isUserLogged);
  // }

  render() {
    return (
      <React.Fragment>
        <Navbar
          bg="white"
          expand="xl"
          sticky="top"
          style={{
            overflowX: "hidden",
            boxShadow: "0px 4px 27px -21px #67548c",
            minHeight: "88px",
          }}
        >
          <Navbar.Brand href="/" className="brand" style={{ display: "flex" }}>
            <img
              alt="StandWeSpeak"
              src={company_logo}
              width="70"
              height="70"
              style={{ marginTop: "-1%", marginBottom: "-2%" }}
              className="header-logo d-inline-block align-top"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginLeft: "10px",
              }}
            >
              <p
                className="header-title"
                style={{ marginBottom: "1px", marginTop: "7px" }}
              >
                {i18n.t("company_name")}
              </p>
              <small className="header-sub-title" style={{ fontSize: "14px" }}>
                {i18n.t("slogan")}
              </small>
            </div>
          </Navbar.Brand>

          {/* <Navbar.Brand href="#home">StandWeSpeak</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link to="/" className="nav-link">
                {i18n.t("home")}
              </Link>

              <Link to="/About" className="nav-link">
                {i18n.t("about_us")}
              </Link>

              <Link to="/OurBlog" className="nav-link">
                {i18n.t("blog")}
              </Link>

              {/* <Link to="/Resources" className="nav-link">
                {i18n.t("resources")}
              </Link> */}

              <Link to="/Team" className="nav-link">
                {i18n.t("team")}
              </Link>

              {/* <Link to="/Videos" className="nav-link">
                Videos
              </Link> */}

              {/* <Link to="/Shop" className="nav-link">
                Shop
              </Link> */}

              {/* <Link to="/Consultancy" className="nav-link">
                Consultancy
              </Link> */}

              {/* <Nav.Link href="/DiscussionForum">Discussion Forum</Nav.Link> */}

              {/* <Nav.Link href="/ResearchPage">Research</Nav.Link> */}

              <Nav.Link
                to="/"
                onClick={async (e) => {
                  e.preventDefault();
                  await AnalyticsService.gaEvent(
                    `Home-tabs`,
                    `tab clicked`,
                    `Get Involved`,
                    1,
                  );
                  this.redirectTo(1);
                }}
                smooth="true"
                duration={500}
              >
                {i18n.t("careers")}
              </Nav.Link>

              <Nav.Link
                to="/"
                onClick={async (e) => {
                  e.preventDefault();
                  await AnalyticsService.gaEvent(
                    `Home-tabs`,
                    `tab clicked`,
                    `Contact Us`,
                    1,
                  );
                  this.redirectTo(2);
                }}
                smooth="true"
                duration={500}
              >
                {i18n.t("contact_us")}
              </Nav.Link>

              <Nav.Link style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 96,
                    margin: "auto",
                  }}
                  onClick={() =>
                    this.setState({
                      showTranslationMenu: !this.state.showTranslationMenu,
                    })
                  }
                >
                  <div style={{ flex: 1 }}>
                    {/* {this.renderFlag()}  */}
                    {t(`${this.languages[this.state.translation]}`)}
                  </div>
                  <FaChevronDown style={{ flex: 1 }} />
                </div>
                {this.state.showTranslationMenu ? (
                  <div className="header-login-dropdown-menu">
                    <ul>
                      <li
                        className={`color-black ${
                          this.state.translation === "en" &&
                          "header-login-dropdown-selected"
                        }`}
                        onClick={() => {
                          this.setState({
                            translation: "en",
                            showTranslationMenu: false,
                          });
                          i18n.changeLanguage("en");
                        }}
                      >
                        {t("English")}
                      </li>
                      <li
                        className={`color-black ${
                          this.state.translation === "kr" &&
                          "header-login-dropdown-selected"
                        }`}
                        onClick={() => {
                          this.setState({
                            translation: "kr",
                            showTranslationMenu: false,
                          });
                          i18n.changeLanguage("kr");
                        }}
                      >
                        {t("Korean")}
                      </li>
                    </ul>
                  </div>
                ) : null}
              </Nav.Link>

              {/* {this.props.login.isUserLogged ? (
                <Nav.Link
                  style={{ position: "relative" }}
                  className={
                    this.state.showDropdownMenu
                      ? "header-login-dropdown-selected"
                      : ""
                  }
                >
                  <div
                    onClick={() =>
                      this.setState({
                        showDropdownMenu: !this.state.showDropdownMenu,
                      })
                    }
                  >
                    <IconContext.Provider value={{ size: "24px" }}>
                      <div>
                        <FaUserCircle />
                      </div>
                    </IconContext.Provider>
                  </div>
                  {this.state.showDropdownMenu ? (
                    <div className="header-login-dropdown-menu">
                      <ul>
                        <li className="color-black">
                          <figure
                            style={{
                              width: "130px",
                              height: "130px",
                              margin: "auto",
                            }}
                          >
                            <img
                              src={
                                this.props.login?.user?.image_url
                                  ? this.props.login.user.image_url
                                  : dummyProfile
                              }
                              className="rounded-circle text-center border-0 w-100 h-100 p-2"
                              alt="profile"
                            />
                          </figure>
                        </li>
                        <li className="color-black">
                          Profile <br />{" "}
                          <b>
                            {this.props.login.user
                              ? this.props.login.user.name.fname
                              : "< no-name >"}
                          </b>{" "}
                        </li>
                        <li className="color-black">
                          <Link to="/AdminApp" className="anchor-tag">
                            Appointments
                          </Link>
                        </li>
                        <li
                          className="color-black"
                          onClick={(e) => this.handleLogout(e)}
                        >
                          Logout
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </Nav.Link>
              ) : (
                <Nav.Link
                  onClick={() => this.props.dispatch(setShowModal(true))}
                  smooth="true"
                  duration={500}
                >
                  <IconContext.Provider value={{ size: "24px" }}>
                    <div>
                      <FaUserCircle />
                    </div>
                  </IconContext.Provider>
                </Nav.Link>
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(Header);
