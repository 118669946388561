import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiExternalLinkLine } from "react-icons/ri";
import WorkWithUs from "../../Assets/Images/WorkWithUs.gif";
import Heading from "../Reusable/Heading";
import AnalyticsService from "../../Services/Analytics";
import "./style.css";

export default () => {
  const { t } = useTranslation();
  let count = "05";
  let heading = t("work_title");
  let title = t("work_subtitle");

  const handleOnClick = (key) => {
    AnalyticsService.gaEvent(
      "Home-hiring",
      `button clicked`,
      `https://forms.gle/${key}`,
      1,
    );
    const win = window.open(`https://forms.gle/${key}`, "_blank");
    win.focus();
  };

  return (
    <React.Fragment>
      <div id="hiring" className="outer-container">
        <Heading
          className="px-5"
          count={count}
          heading={heading}
          title={title}
          titleColor="#3a3939"
          headingColor="#313131"
        />
        <h3 className="px-5">{t("work_body_1")}</h3>
        <div
          id="hiring-container"
          className="container-fluid"
          style={{ marginTop: "5%", maxWidth: "1130px" }}
        >
          <div className="row">
            <div className="col-lg-6">
              <img
                src={WorkWithUs}
                alt="work with us"
                style={{ paddingBottom: "4%", width: "100%" }}
                data-aos="fade-zoom-in"
                data-aos-anchor="#hiring-container"
              />
            </div>
            <div className="col-lg-6">
              <Card
                className="card-shadow"
                style={{
                  borderBottom: "3px solid #fc5f45",
                  paddingTop: "1%",
                  paddingBottom: "3%",
                  marginBottom: "2%",
                  borderRadius: "0px",
                }}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Card.Body>
                  <Card.Title
                    className="job-designation"
                    style={{ textDecorationColor: "#fc5f45" }}
                    onClick={() => handleOnClick("v1f53ae2QrLHQYhM8")}
                  >
                    {t("work_apply_title")} <RiExternalLinkLine />
                  </Card.Title>
                  <Card.Text className="intern-description">
                    {t("work_apply_subtext")}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="card-shadow"
                style={{
                  borderBottom: "3px solid #38c695",
                  paddingTop: "1%",
                  paddingBottom: "3%",
                  marginBottom: "2%",
                  borderRadius: "0px",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-anchor="#hiring-container"
              >
                <Card.Body>
                  <Card.Title
                    className="job-designation"
                    style={{ textDecorationColor: "#37c594" }}
                    onClick={(e) => handleOnClick("gKM6krKUJuqToUq7A")}
                  >
                    {t("work_intern_apply_title")} <RiExternalLinkLine />
                  </Card.Title>
                  <Card.Text className="intern-description">
                    {t("work_intern_apply_subtext")}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="card-shadow"
                style={{
                  borderBottom: "3px solid #b27cf5",
                  paddingTop: "1%",
                  paddingBottom: "3%",
                  borderRadius: "0px",
                }}
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-anchor="#hiring-container"
              >
                <Card.Body>
                  <Card.Title
                    className="job-designation"
                    style={{ textDecorationColor: "#b27cf5" }}
                    onClick={() => handleOnClick("ddRMLUcj8sAs8bNAA")}
                  >
                    {t("work_expert_apply_title")} <RiExternalLinkLine />
                  </Card.Title>
                  <Card.Text className="intern-description">
                    {t("work_expert_apply_subtext")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
