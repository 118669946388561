import { CHATBOT_TOPICS } from "../../types/chatbot";

const linked_consults = [
  {
    consults_title: "Consult an expert",
    consults_subtitle: "Gynecologist",
    consults_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Gynecologist.png",
    consults_url: "https://rzp.io/l/gynecologist",
  },
  {
    consults_title: "Consult an expert",
    consults_subtitle: "Counselor",
    consults_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Counselor.png",
    consults_url: "https://rzp.io/l/mTDmrJtY",
  },
  {
    consults_title: "Consult an expert",
    consults_subtitle: "Lawyer",
    consults_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Lawyer.png",
    consults_url: "https://rzp.io/l/kMAKqmh44",
  },
  {
    consults_title: "Consult an expert",
    consults_subtitle: "Sexologist",
    consults_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Sexologist.png",
    consults_url: "https://rzp.io/l/d1oTRkKj",
  },
  {
    consults_title: "Consult an expert",
    consults_subtitle: "Intimacy Coach",
    consults_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Intimacy+Coach.png",
    consults_url: "https://rzp.io/l/DnmnIOFK5",
  },
];

export default [
  {
    category: Object.values(CHATBOT_TOPICS),
    list: linked_consults,
  },
];
