import React from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight, FaFacebookMessenger } from "react-icons/fa";
import Call from "../../Assets/Chatbot/call.png";
import Age from "../../Assets/Chatbot/age.png";
import Confidential from "../../Assets/Chatbot/confidential.png";
import LGBT from "../../Assets/Chatbot/lgbt.png";
import MEDICAL from "../../Assets/Chatbot/medical.png";
import NonJudge from "../../Assets/Chatbot/non-judgemental.png";
import { ExpertSection } from "../../Components/ExpertSection";
import Heading from "../Reusable/Heading";
import ChatbotBannerImageIcon from "../../Assets/Images/home/chatbot_banner_image.png";
import AnalyticsService from "../../Services/Analytics";
import "./style.css";

export const ChatbotBanner = () => {
  const { t } = useTranslation();

  const features = [
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_non_judgemental"),
      title2: "",
      icon: NonJudge,
    },
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_lgbt_friendly"),
      title2: "",
      icon: LGBT,
    },
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_confidential"),
      title2: "",
      icon: Confidential,
    },
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_medically_accurate"),
      title2: "",
      icon: MEDICAL,
    },
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_age_appropriate"),
      title2: "",
      icon: Age,
    },
    {
      logo: <FaFacebookMessenger />,
      title1: t("feature_24_7_available"),
      title2: "",
      icon: Call,
    },
  ];

  return (
    <div className="chatbot-introduce">
      <div
        className="chatbot-introduce-banner"
        style={{ minHeight: "0", paddingBottom: 0 }}
      >
        <div className="chatbot-banner-content" style={{ width: "100%" }}>
          <div
            className="chatbot-banner-content-chat"
            style={{ marginLeft: "auto", marginRight: "0", textAlign: "left" }}
          >
            <h2 className="banner-heading">{t("banner_title")}</h2>
            <p className="banner-para mb-24">{t("banner_subtitle")}</p>
            <p className="banner-para">{t("banner_subtitle_2")}</p>
            <div
              className="sws-button"
              onClick={async (e) => {
                e.preventDefault();
                await AnalyticsService.gaEvent(
                  "Chatbot-website",
                  "outbound link",
                  "go to chatbot",
                  1,
                );
                window.open("/Chatbot", "_self");
              }}
            >
              Let's chat <FaChevronRight className="ml-2" />
            </div>
          </div>
        </div>
        <div style={{ width: "40%" }}>
          <img
            src={ChatbotBannerImageIcon}
            style={{ width: "100%", minHeight: "100%" }}
            alt="Chatbot Banner"
          />
        </div>
      </div>
      <ExpertSection />
      <div
        id="chatbot-introduce-feature"
        className="chatbot-introduce-feature-container"
      >
        <Heading
          count={"02"}
          heading={t("feature_heading")}
          title={t("feature_title")}
          headingColor={"#000"}
        />
        <p>{t("feature_subtitle")}</p>
        <div className="chatbot-introduce-feature">
          {features.map((x, i) => {
            return (
              <div key={i} className="chatbot-introduce-feature-items">
                <div className="feature-items-icon">
                  <img
                    src={x.icon}
                    alt="feature icons"
                    className="feature-items-icons"
                  />
                </div>
                <div className="feature-items-title">{x.title1}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
