import React, { useState, useEffect } from "react";
import Axios from "axios";
import Footer from "../../Components/Footer";
import { RiMenu2Line, RiSearchLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { Col, Dropdown, DropdownButton, Row, Form } from "react-bootstrap";
import { FaAngleDown, FaCheck, FaPlus } from "react-icons/fa";
import styled, { css } from "styled-components";
import { redirectToSite, makeLowerCase, makeUpperCase } from "../../Utils/util";
import AnalyticsService from "../../Services/Analytics";
import { Helmet } from "react-helmet";
import "./style.css";

function BlogCard(props) {
  const LinkAnchor = styled.a`
    text-decoration: none;

    & :hover {
      text-decoration: none !important;
      color: inherit;
    }
  `;

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>StandWeSpeak | Blog | Awkward Questions | Easy Answers</title>
        <meta
          name="title"
          content="StandWeSpeak | Blog | Awkward Questions | Easy Answers"
        />
        <meta
          name="description"
          content="Read and learn about sex, sexuality, gender, pregnancy, relationships and so much more through our curated blogs."
        />
        <meta
          name="keywords"
          content="Blogs, Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <div className="blogPost">
        <div className="cardImage">
          <a
            href={`/Blog/${props.blog._id}`}
            target="_self"
            rel="noopener noreferrer"
            onClick={async (e) => {
              e.preventDefault();
              await AnalyticsService.gaEvent(
                "Blogs-website",
                `outbound link`,
                `${props.blog.title}`,
                1,
              );
              redirectToSite(`/Blog/${props.blog._id}`);
            }}
          >
            <img src={props.blog.thumbnail} alt="thumbnail" />
          </a>
        </div>
        <div className="blogDescription">
          <div>
            <h5 className="blogTitle">{props.blog.title}</h5>
            <p
              style={{
                fontSize: "13px",
                lineHeight: "0.1em",
                color: "#6d6b6b",
              }}
            >
              {props.blog.createdBy}
            </p>
          </div>
          <p className="briefDescription">
            {props.blog.briefDescription.slice(0, 200) + "..."}
          </p>
        </div>
        <div className="know-more">
          {/* <LinkAnchor onClick={() => props.history.push(`/Blog/${props.blog._id}`)} style={{ textDecoration: 'none' }}> */}
          <LinkAnchor
            href={`/Blog/${props.blog._id}`}
            target="_self"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              redirectToSite(`/Blog/${props.blog._id}`);
            }}
            style={{ textDecoration: "none", cursor: "pointer", color: "blue" }}
          >
            READ MORE
          </LinkAnchor>
        </div>
      </div>
    </React.Fragment>
  );
}

const CustomInput = styled(Form.Control)`
  border: none;
  border-radius: 0px;
  outline: none;
  height: auto;
  font-size: 20px;
  padding: 2px 11px;
  border-bottom: 1px solid #ced4da;
  font-family: "Poppins";
  &:focus {
    outline: none;
    border-color: #ced4da;
    box-shadow: none;
  }
`;

const DropdownItem = styled.button`
  background: #42414b;
  width: 100%;
  border-radius: 3px;
  border: 0px;
  color: white;
  padding: 4px 3px;
  margin: 0 auto;
  border-bottom: 1px solid #42414b;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    color: #7be2a4;
  }
  &.selecteditem {
    color: #7be2a4;
  }

  ${(props) =>
    props.topBorder &&
    css`
      border-top: 1px solid #42414b;
    `}
`;

const IconMenu = styled.button`
  position: absolute;
  top: 0;
  right: -60px;
  background: white;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 2px;
  padding: 4px 10px;
  margin: 10px;

  &:hover {
    cursor: pointer;
    background-color: lightgray;
  }
`;

const WriteByUsButton = styled.button`
  border: 2px solid white;
  font-size: 20px;
  font-family: "Poppins";
  background: #7be2a4;
  border-radius: 6px;
  padding: 3px 11px;
  color: white;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

export default function OurBlog(props) {
  const [blogs, setblogs] = useState([]);
  const [topics, setTopic] = useState([]);
  const [limit, setLimit] = useState(12);
  const [searchval, setsearchval] = useState("");
  const [showSidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState({
    topic: "",
    written_by: "",
  });

  useEffect(() => {
    const getTopic = () => {
      const data = [
        "Body and Development",
        "Consent",
        "Understanding Emotions",
        "Gender and Sexuality",
        "Relationships",
        "Pleasure",
        "Sex and Reproduction",
        "STDs and STIs",
        "Pregnancy and Contraception",
        "Abortion",
        "Violence and staying safe",
        "Life-skills Development",
        "Menstruation",
        "Media Literacy",
        "Parent’s Guide",
        "Future of Sex-Tech",
      ];
      // data = data.map(item => item.topic).filter((value, index, self) => self.indexOf(value) === index)
      setTopic(data);
    };
    const getBlogs = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/blog/find`,
      );
      setblogs(res.data);
      // getTopic(res.data);
      getTopic();
    };
    getBlogs();
  }, []);

  const handleLoadMoreBlogs = () => {
    setLimit(limit + 12);
  };

  const handleChange1 = async (value) => {
    await AnalyticsService.gaEvent(
      "Blogs-website",
      `category chosen`,
      `${value}`,
      1,
    );
    if (value !== filter.topic) {
      setFilter((prevState) => ({
        ...prevState,
        topic: value,
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        topic: "",
      }));
    }
  };

  const handleChange2 = (value) => {
    if (value === filter.written_by) {
      setFilter((prevState) => ({
        ...prevState,
        written_by: "",
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        written_by: value,
      }));
    }
  };

  const blogFiltered =
    blogs
      .filter(
        (v) =>
          makeLowerCase(v.briefDescription).includes(searchval.toLowerCase()) ||
          v.title.toLowerCase().includes(searchval.toLowerCase()) ||
          v.createdBy.toLowerCase().includes(searchval.toLowerCase()),
      )
      .filter((blog) =>
        makeLowerCase(blog.written_by).includes(
          filter.written_by.toLowerCase(),
        ),
      )
      .filter((blog) =>
        makeLowerCase(blog.topic).includes(filter.topic.toLowerCase()),
      )
      .slice(0, limit) || [];

  return (
    <React.Fragment>
      <div className="blogHomeHeader flx mb-0">
        <h1>Our Blog!</h1>
        <p>
          "For all the things you were too afraid to talk about. Start here,
          from our hand-crafted articles & pass-on the wisdom to others."
        </p>
      </div>
      <div className="container-fluid px-0 blog-container">
        <div
          className={
            "blog-sidebar border-right " +
            (showSidebar ? "blog-sidebar-show" : "blog-sidebar-hide")
          }
        >
          <IconMenu onClick={() => setSidebar(!showSidebar)}>
            <IconContext.Provider
              value={{
                color: "#42414b",
                size: "20px",
                className: "global-class-name",
              }}
            >
              <div>
                <RiMenu2Line />
              </div>
            </IconContext.Provider>
          </IconMenu>

          {showSidebar ? (
            <div>
              <h4 className="mt-2 mb-3 p-2 text-light border-bottom">
                <strong>Topics</strong>
              </h4>
              {topics.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <DropdownItem
                      topBorder
                      onClick={() => handleChange1(item)}
                      className={
                        "text-left " +
                        (filter.topic === item ? "selecteditem" : "")
                      }
                    >
                      {item}
                    </DropdownItem>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          <Row
            className={
              "pt-2 pl-3 mb-3 mx-1 border-bottom justify-content-center"
            }
          >
            <Col xs={10} md={10} lg={5} className="blog-flex-input mb-2">
              <CustomInput
                onChange={(e) => setsearchval(e.target.value)}
                value={searchval}
              />
              <RiSearchLine
                style={{
                  margin: "auto",
                  marginTop: "8px",
                  position: "relative",
                  right: "25px",
                }}
              />
            </Col>
            <Col xs={6} md={6} lg={2}>
              <DropdownButton
                key={2}
                id={`dropdown-button-drop-${2}`}
                size="md"
                title="Written By "
                variant="light"
                className="blog-btn-block"
                name="writtenBy"
                onSelect={(e) => handleChange2(e)}
              >
                {blogs
                  .map((item) => item.written_by)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((wb, i) => {
                    return (
                      <Dropdown.Item key={i} eventKey={wb}>
                        {makeUpperCase(wb)}
                        {filter.written_by === wb ? (
                          <FaCheck className="pl-1" />
                        ) : (
                          ""
                        )}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
            </Col>
            <Col xs={6} md={6} lg={3}>
              <WriteByUsButton
                onClick={async () => {
                  await AnalyticsService.gaEvent(
                    "Blogs-website",
                    `outbound link`,
                    `Clicked on 'Write By Us'`,
                    1,
                  );
                  redirectToSite("https://forms.gle/bN2CaybyspSNDV5v8");
                }}
              >
                <FaPlus style={{ fontSize: "12px" }} />
                &nbsp; Write With Us
              </WriteByUsButton>
            </Col>
          </Row>
          <Row className="justify-content-left">
            {blogFiltered.length ? (
              blogFiltered.map((blog, i) => {
                return (
                  <div
                    key={i}
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-10"
                    style={{ padding: "20px 30px" }}
                  >
                    <BlogCard blog={blog} {...props} />
                  </div>
                );
              })
            ) : (
              <Col md={12} className="d-flex justify-content-center">
                <div className="blog-no-results">
                  <h2>None Found</h2>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <div style={{ cursor: "pointer" }} onClick={handleLoadMoreBlogs}>
        {blogs.length > limit ? (
          <IconContext.Provider value={{ size: "50px" }}>
            <FaAngleDown name="load more" />
          </IconContext.Provider>
        ) : null}
      </div>
      <Footer maxWidth="97vw" />
    </React.Fragment>
  );
}
