import ReactGA4 from "react-ga4";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const { default: axios } = require("axios");

const AnalyticsService = {
  init: (gaToken, lrToken) => {
    if (process.env.NODE_ENV === "production") {
      console.log("All analytics initialized...");
      ReactGA4.initialize(gaToken);
      LogRocket.init(lrToken);
      Sentry.init({
        dsn: "https://a647390ece834df58ec1bfbf7dd4c46e@o4504107369299968.ingest.sentry.io/4504107370545152",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra("sessionURL", sessionURL);
        });
      });
    }
  },
  // lrUserIdentity: () => {
  //   if (process.env.NODE_ENV === 'development') {
  //     LogRocket.identify('777', {
  //       name: 'Steven',

  //       // Add your own custom user variables here, ie:
  //       subscriptionType: 'developer'
  //     });
  //   }
  // },
  gaPageView: (pathname) => {
    ReactGA4.send({ hitType: "pageview", page: pathname });
  },
  gaEvent: (category, action, label) => {
    ReactGA4.event({
      category,
      action,
      label,
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  },
  setFirstDimension: (value) => {
    ReactGA4.set({
      dimension1: value,
    });
  },
  markQuestion: async (payload) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/analytics/insert`,
        payload,
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  updateRCount: async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/bot/updateRCount?id=${id}`,
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
};
export default AnalyticsService;
