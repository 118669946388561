import React, { useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

//importing components
import Home from "./Pages/Home";
import Header from "./Components/Header";
import OurBlog from "./Pages/OurBlog";
import Blog from "./Pages/Blog";
import JobApplication from "./Pages/JobApplication";
import About from "./Pages/About";
import ForumPost from "./Components/ForumPost";
import Team from "./Pages/Team";
import CoreTeam from "./Pages/CoreTeam";
// import Consultant from "./Pages/Consultants";
// import Videos from "./Pages/Videos";
// import Products from "./Pages/Products";
// import WhatsAppFloat from "./Components/WhatsAppFloat";
import LoginModal from "./Components/LoginModal";
import FourZeroFour from "./Components/NotFound";
import AdminApplication from "./Pages/AdminApplication";
import ChatBot from "./Pages/ChatBot";
import AnalyticsService from "./Services/Analytics";
import Resources from "./Pages/Resources/Index";
import Privacy from "./Pages/Privacy";
import Refund from "./Pages/Refund";
import TermsConditions from "./Pages/TermsConditions";
import "./Utils/translation";
import "./App.css";
import Shipping from "./Pages/Shipping";

function App() {
  let showModal = useSelector((state) => state.LoginReducer.showModal);

  useEffect(() => {
    // initialization codes
    // param 1 GA4 tag
    // param 2 logRocket tag
    AnalyticsService.init("G-EXQW8KGZKJ", "pqprjk/sws");
    // AnalyticsService.lrUserIdentity()
    // console.log(store.getState());
    // store.subscribe(() => { console.log(store.getState()) });

    var params =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    if (params.includes("hire")) {
      let rn = document.getElementById("hiring");
      if (rn) {
        rn.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    if (params.includes("mail")) {
      let cm = document.getElementById("contact-mail");
      if (cm) {
        cm.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (params.includes("developer")) {
      window.open("https://stevenferns.me");
    }

    // function isCalendlyEvent(e) {
    //   return e.data.event && e.data.event.indexOf("calendly") === 0;
    // }

    // window.addEventListener("message", function (e) {
    //   if (isCalendlyEvent(e)) {
    //     console.log(e.data);
    //   }
    // });
  }, []);
  return (
    <div className="App">
      <Router>
        {window.location.pathname !== "/Chatbot" && <Header />}
        <Switch>
          {/* <Route path='*' exact={true} component={GenericNotFound} /> */}
          <Route
            exact
            path="/"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Home {...props} />;
            }}
          />
          <Route
            path="/OurBlog"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <OurBlog {...props} />;
            }}
          />
          <Route
            path="/Blog/:id"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Blog {...props} />;
            }}
          />
          {/* <Route
            path="/Videos"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Videos {...props} />;
            }}
          /> */}
          {/* <Route
            path="/Shop"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Products {...props} />;
            }}
          /> */}
          {/* <Route
            path="/Consultancy"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Consultant {...props} />;
            }}
          /> */}
          <Route
            path="/JobApplication/:id"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <JobApplication {...props} />;
            }}
          />
          {/* <Route path="/DiscussionForum" render={props => { AnalyticsService.gaPageView(props.location.pathname); return <MyRoute DiscussionForum }}/> */}
          <Route
            path="/About"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <About {...props} />;
            }}
          />
          <Route
            path="/Post/:id"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <ForumPost {...props} />;
            }}
          />
          <Route
            path="/Team"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Team {...props} />;
            }}
          />
          <Route
            path="/CoreTeam"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <CoreTeam {...props} />;
            }}
          />
          <Route
            path="/AdminApp"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <AdminApplication {...props} />;
            }}
          />
          <Route
            path="/Chatbot"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <ChatBot {...props} />;
            }}
          />
          <Route
            path="/Resources"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Resources {...props} />;
            }}
          />
          <Route
            exact
            path="/Privacy"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Privacy {...props} />;
            }}
          />
          <Route
            exact
            path="/Refund"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Refund {...props} />;
            }}
          />
          <Route
            exact
            path="/Shipping"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Shipping {...props} />;
            }}
          />
          <Route
            exact
            path="/TermsConditions"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <TermsConditions {...props} />;
            }}
          />
          {/* <Route
            exact
            path="/discuss/"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <DiscussForum {...props} />;
            }}
          />
          <Route
            exact
            path="/discuss/more"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <More {...props} />;
            }}
          />
          <Route
            exact
            path="/discuss/add"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Add {...props} />;
            }}
          />
          <Route
            exact
            path="/discuss/comment"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <Comments {...props} />;
            }}
          />
          <Route
            path="/ResearchPage"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <ResearchPage {...props} />;
            }}
          />
          <Route
            path="/SocialsClub"
            render={(props) => {
              AnalyticsService.gaPageView(props.location.pathname);
              return <SocialsClub {...props} />;
            }}
          /> */}
          <Route component={FourZeroFour} />
        </Switch>
        {showModal ? <LoginModal /> : ""}
        {window.localStorage.getItem("cookies") !== "yes" && (
          <div className="manageCookie">
            <p>
              <b>Notice.</b> StandWeSpeak.com uses cookies to provide necessary
              website functionality, improve your experience and analyze our
              traffic. By using our website, you agree to our{" "}
              <Link to="/Privacy">Privacy Policy</Link> and our cookies usage.
            </p>
            <button
              type="button"
              onClick={(e) => {
                window.localStorage.setItem("cookies", "yes");
                e.currentTarget.parentElement.style.display = "none";
              }}
            >
              I AGREE
            </button>
          </div>
        )}
        {/* <WhatsAppFloat /> */}
      </Router>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(App);
// export default App;
