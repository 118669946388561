import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player/lazy";
import AnalyticsService from "../../Services/Analytics";

export const VideoList = React.memo(({ data, category }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    if (data) {
      setVideos(data);
    }
  }, [data]);

  return (
    <Slider className="bot-video-list" {...settings}>
      {videos.length > 0 &&
        videos.map((video, i) => (
          <div key={i} className="bot-video-item">
            <div className="bot-video-player">
              <div className="bot-video-img-container">
                <ReactPlayer
                  style={{ minWidth: "80px" }}
                  width="100%"
                  height="100%"
                  url={video.video_url}
                  onStart={() => {
                    AnalyticsService.gaEvent(
                      "Videos-chatbot",
                      `Video Played`,
                      `${video.video_url}`,
                      1,
                    );
                  }}
                  controls={true}
                />
              </div>
            </div>
            <div className="bot-video-title">
              {video.video_title?.slice(0, 72)}
            </div>
          </div>
        ))}
      {/* </div> */}
    </Slider>
  );
});
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
