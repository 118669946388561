import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Axios from "axios";
import "./style.css";
import Footer from "../../Components/Footer";
import { Helmet } from "react-helmet";

export default function Blog(props) {
  const [blog, setBlog] = useState({});
  const [loading, isLoaded] = useState(false);
  useEffect(() => {
    const getBlogById = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/blog/findOne?id=${props.match.params.id}`,
      );
      setBlog(res.data);
      isLoaded(true);
    };
    getBlogById();
    window.scrollTo(0, 0);
  }, [props.match.params.id]);

  if (!loading) {
    return (
      <React.Fragment>
        <div className="flex-center">
          <img src="./logo.png" alt="StandWeSpeak" />
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog - {blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="description" content={blog.briefDescription} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.briefDescription} />
        <meta property="og:image" content={blog.thumbnail} />
        <meta property="og:url" content={`/Blog/${blog._id}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="blogHeader flx">
        <h2>{blog.title}</h2>
        <div className="headerImage">
          <img src={blog.thumbnail} alt="thumbnail" />
        </div>
      </div>
      <div className="flx blogWrapper">
        <div className="blogContent flx">
          <ReactMarkdown source={blog.markdown} escapeHtml={false} />
        </div>
      </div>
      <Footer maxWidth="95vw" />
    </React.Fragment>
  );
}
