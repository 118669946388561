import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.css";

function Privacy() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>StandWeSpeak | Privacy Policy</title>
        <meta name="title" content="StandWeSpeak | Privacy Policy" />
        <meta
          name="description"
          content="Learn more about our privacy policy. "
        />
        <meta
          name="keywords"
          content="Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <div className="wrapper serviceBody">
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Privacy Policy- StandWeSpeak</h1>
                <br />
                <h2>StandWeSpeak Privacy Policy</h2>
                <p>
                  This policy describes our policies and procedures for
                  collection, transmission, storage, processing, disclosure and
                  protection of any data, including, but not limited to,
                  personal data provided by you as a user while using the
                  service. user shall mean any person/ persons, who visits,
                  uses, deals with and/ or transacts through StandWeSpeak
                  website (“privacy policy”). Please also read our terms of
                  service (“agreement”) which sets out the terms governing the
                  services. the terms and conditions, privacy policy and any
                  other terms and conditions as may be applicable shall
                  hereinafter collectively be referred to as “agreement” or
                  “contract”.
                </p>
                <p>Effective Date: September, 2021</p>
                <h2>Important Notice:</h2>
                <ul>
                  <li>
                    If You are having suicidal thoughts or planning to act on
                    suicidal thoughts, or if You feel that You may be a danger
                    to Yourself or to others, or if You otherwise have any
                    medical or mental health emergency or severe mental health
                    condition, or if You are in a crisis or trauma or abuse,
                    please discontinue use of the Service immediately and call
                    the relevant emergency number in Your country and notify the
                    police or emergency medical Services.
                  </li>
                  <li>
                    If You are less than 18 years of age, please read through
                    the StandWeSpeak Privacy Policy and the StandWeSpeak Terms
                    of Service with Your parent or legal guardian or check with
                    Your Institution to understand eligibility before use. If
                    you are a minor in your jurisdiction, your registration and
                    use of our Services must be with the supervision of an
                    adult. StandWeSpeak/Mae is not to be used by children under
                    13 years.
                  </li>

                  <li>
                    You must have attained the age of majority to be able to use
                    and access our Services. As a parent or legal guardian,
                    please do not allow your minors under your care to submit
                    Personal Information to us. In the event that such Personal
                    Information of minor is disclosed to us, you hereby consent
                    to the processing of the minor’s Personal Information and
                    accept and agree to be bound by this Privacy Policy and take
                    responsibility for his or her actions.
                  </li>

                  <li>
                    Your interaction with the StandWeSpeak/Mae Bot is with an AI
                    chatbot and not a human. The Bot is restricted in the means
                    of response, and the intended use is for providing
                    evidence-based tools and techniques to manage emotions and
                    encourage sexual health and mental well-being in a self-help
                    context. It is not intended for providing diagnosis,
                    treatment or cure of a condition or disease. The Bot cannot
                    and will not offer advice on issues it does not recognize.
                  </li>

                  <li>
                    The StandWeSpeak/Mae Expert/Consultancy Service will use
                    online sessions mode to help You work towards Your personal
                    well-being goals. The underlying principle of the
                    Expert/Consultancy Service is that You have the knowledge
                    and capacity to make desired changes in Your life. The role
                    that an Expert/Consultant will play is to support You in
                    finding Your own way, help You tap into Your own strengths
                    and abilities, so that You can identify and use resources
                    around You to fill any gaps.
                  </li>

                  <li>
                    StandWeSpeak/Mae Expert/Consultancy services are not
                    intended to provide a diagnosis, prognosis, treatment or
                    cure for a disease/condition/disorder or disability or
                    provide any type of state-regulated mental or sexual health
                    services in Your country of residence. It is an enabling and
                    empowering mode of support, rather than treatment of illness
                    or a health condition.
                  </li>

                  <li>
                    By using the StandWeSpeak/Mae Expert/Consultant Services,
                    You understand and agree that the Expert assigned to work
                    with You will be located remotely and may not be located in
                    Your country or state of residence.
                  </li>

                  <li>
                    For safety and security reasons, We strongly recommend that
                    You keep Your conversations with StandWeSpeak/Mae private.
                  </li>

                  <li>
                    By using the Service, You understand and agree that Your
                    submitted Data will be transferred, stored and processed in
                    Our secure servers.
                  </li>
                </ul>
                <hr />
                <h2>Introduction</h2>
                <p>
                  Welcome to StandWeSpeak/Mae Website/Bot, the online service of
                  StandWeSpeak (hereinafter “StandWeSpeak”, “StandWeSpeak/Mae”,
                  “We”, “Us”, “Mae” or “Our”). When you as a Data Subject
                  (“User”, or “You/r”) use StandWeSpeak’s or Mae’s Services, You
                  trust us with Your Data. This Privacy Policy governs Your Use
                  of Our web browser based application either on the
                  StandWeSpeak/Mae website or integrated within an any website
                  (hereinafter the “StandWeSpeak/Mae website”) created by
                  StandWeSpeak and also covers Your use of Our Website, other
                  websites maintained by Us. This Privacy Policy along with
                  StandWeSpeak/Mae Terms of Service constitutes a legal
                  agreement between You and StandWeSpeak.
                </p>
                <p>
                  This Privacy Policy (“Policy” or “Privacy Policy”) has been
                  drafted in accordance with the Information Technology Act,
                  2000, the Information Technology (Reasonable security
                  practices and procedures and sensitive personal data or data)
                  Rules, 2011, including (but not limited to) requirements
                  within General Data Protection Regulation (GDPR) (EU) 2016/679
                  regulation, the UK-GDPR (collectively referred to as “Data
                  Protection Laws”).
                </p>
                <p>
                  For the purposes of processing Your data, StandWeSpeak/Mae,
                  the makers of StandWeSpeak/Mae Website/Bot will act as the
                  Data Controller. StandWeSpeak is a partnership-firm,
                  incorporated and existing under the laws of India and having
                  its registered office at 304, Amaltas, D.M.Vatika, Shankar
                  nagar, Raipur, Chhattisgarh, India-492001.
                </p>
                <h2>What does this Privacy Policy apply to?</h2>
                <p>
                  This Privacy Policy applies to the data You provide Us when
                  You Use the StandWeSpeak/Mae Website/Bot and Service. The web
                  browser based AI chatbot service, digital premium service and
                  the Expert/Consultancy Service provided within and outside the
                  StandWeSpeak/Mae Website are collectively referred to as the
                  "Service(s)".
                </p>
                <p>
                  This Privacy Policy is meant to help You understand what data
                  We collect, why We collect it, what We do with it, how You can
                  manage and control the Use of Your data and the rights You
                  have to access and control Your Personal data. Please read the
                  definitions in the next section to understand the
                  terminologies used in this Privacy Policy.
                </p>
                <p>
                  We will always respect and protect Your privacy, and this
                  forms a part of Our guiding principles aligned to the seven
                  key principles of GDPR.{" "}
                </p>
                <p>
                  Your use of the StandWeSpeak/Mae Website/Bot or services will
                  be governed by this Privacy Policy as applicable to the
                  StandWeSpeak/Mae Website/Bot together with all policies,
                  notices, guidelines, disclaimers that are published and shared
                  with You from time to time including but not limited to
                  StandWeSpeak/Mae Terms of Service.
                </p>
                <p>
                  Users may request additional Services from StandWeSpeak or
                  StandWeSpeak affiliates. Because the needs and choices of each
                  User may vary, We may provide separate privacy policies or
                  additions to this Privacy Policy for certain additional
                  Services. Any applicable separate privacy policies and
                  additions will explain the types of data We collect, their
                  purposes of Use and other policies that may apply to that
                  Service.
                </p>
                <p>
                  StandWeSpeak reserves the right to make changes to this
                  Privacy Policy and to make such changes effective for all data
                  We may already have collected from You. If the changes to the
                  Privacy Policy include changes to the collection, storing or
                  processing Your Personal information in a way that infringe
                  into Your privacy, we will notify You clearly about the same
                  and/or seek Your consent for the same where required by the
                  applicable laws and regulations.
                </p>
                <p>
                  Please note that by using StandWeSpeak/Mae Website/Bot or
                  services, You acknowledge and consent to this Privacy Policy
                  and Our Terms of Service.
                </p>
                <h2>Cookies</h2>
                <p>
                  Cookie is a small amount of data generated by a Website and
                  saved by Your Web browser. Cookies are used to store User
                  preferences for a specific site. Use of cookies makes Web-
                  surfing easier. You may refuse to accept Cookies by activating
                  the setting on Your browser which allows You to refuse the
                  setting of Cookies.
                </p>
                <p>
                  Data or Information under this Privacy Policy means Both
                  Personal and Non-Personal data or information
                </p>
                <p>
                  Data Controller means the natural or legal person, public
                  authority, agency or other body which, alone or jointly with
                  others, determines the purposes and means of the processing of
                  personal data.
                </p>
                <p>
                  Data Processor means a natural or legal person, public
                  authority, agency or other body which processes personal data
                  on behalf of the data controller.
                </p>
                <p>
                  Personal data or Personal Information means data relating to
                  an identified or identifiable natural person who can be
                  directly or indirectly identified by reference to an
                  identifier such as full name, identification numbers, location
                  address, online identifier and other identifiers within the
                  definitions of The Information Technology (Reasonable Security
                  Practices and Procedures and Sensitive Personal Data or data)
                  Rules 2011, General Data Protection Regulation (GDPR) (EU)
                  2016/679 regulation and UK-GDPR. Personally identifiable
                  information (PII) and Sensitive or Special Category of
                  Personal data is covered within the definition of Personal
                  Data.
                </p>
                <p>
                  Non-Personal data or Non-Personal Information means any data
                  that does not reveal Your specific identity either directly or
                  indirectly.
                </p>
                <p>
                  Web browser is a software program that allows User to access,
                  retrieve and view data on the World Wide Web. Examples of
                  browsers include Internet Explorer, Firefox, Google Chrome and
                  Safari.
                </p>

                <h2>What is Mae?</h2>
                <p>
                  Mae (developed and powered by StandWeSpeak) is a virtual AI
                  chatbot (“Bot”, “Mae” or “StandWeSpeak/Mae Bot”) that You can
                  chat with, including upon Your choice, the ability to book a
                  teleconsultation session with highly trained sexual and
                  reproductive health professional (“Consultant” or “Expert”),
                  and through a conversational interface get access to tools,
                  resources and techniques to manage your sexual health. The
                  StandWeSpeak/Mae is primarily available as a web browser based
                  system either on the StandWeSpeak/Mae website or integrated
                  within an Institution website. Your Interaction with the Bot
                  is with an artificial intelligence chatbot and not a human.
                  The Bot is restricted in the means of response, and the
                  intended usage of StandWeSpeak/Mae Website is for providing
                  evidence-based tools and techniques to manage sexual and
                  reproductive health as an early intervention tool in a
                  self-help context. You make the choice of using the Bot, based
                  on Your own estimate of need, and agree that this is only
                  suitable for basic self-help. This is not intended to be a
                  replacement for face-to-face expert consultancy, therapy or to
                  provide a diagnosis, prognosis, treatment or cure for a
                  disease/condition/disorder or disability. The Bot cannot and
                  will not offer advice on issues it does not recognize. Using
                  the StandWeSpeak/MaeWebsite, You can track and manage Your
                  sexual health, and learn context-sensitive evidence-based
                  techniques that can help You feel better. StandWeSpeak/Mae App
                  and Service is not intended for use in crisis such as abuse or
                  complex or severe health conditions that causes for example;
                  ideation of suicide, harm to self and others, or for any
                  medical emergencies. StandWeSpeak/Mae Website and Service
                  cannot and will not offer medical or clinical advice. It can
                  only suggest that the user seeks advanced (medical) help.
                </p>
                <h2>Who can Use the Service?</h2>
                <p>
                  You may Use the Service only if You are a natural/legal
                  person, agree to this Agreement and form a binding contract
                  with StandWeSpeak/Mae, and only in compliance with all
                  applicable local, state, national, and international laws,
                  rules and regulations. If You are between 13 and 18 years of
                  age, please read through this StandWeSpeak Privacy Policy and
                  the StandWeSpeak Terms of Service with Your parent or legal
                  guardian, and in such a case the Agreement shall be deemed to
                  be a contract between StandWeSpeak and Your legal guardian or
                  parent and to the extent permissible under applicable laws,
                  enforceable against You. Anyone under 13 is strictly
                  prohibited from creating an account and/or Using the Service.
                </p>
                <p>
                  If Your Institution specifies a different age restriction,
                  such as at least 18 and above, as a condition of using this
                  Service, that restriction shall apply rather than the one
                  above.
                </p>
                <p>
                  If You use the StandWeSpeak/Mae Expert or Consultancy Service,
                  You will be asked to provide a Parental or Legal Guardian
                  consent if You reveal Your age to be between 13 and 18 years.
                  You will be required to inform Your parents or legal guardian
                  and have them send Us an email consent to
                  info@standwespeak.com, as directed by Your StandWeSpeak/Mae
                  Expert/Consultant, using the same email ID that was used to
                  subscribe to Our Service. Without receiving parental or legal
                  guardian consent, We will be unable to offer Our full
                  Services. If Your parents or legal guardian contact Us We will
                  collect minimal Personal Information such as Your name, Your
                  parents name, Your parent’s email address and the consent
                  message. We process Your data for our Legitimate Interest to
                  provide You Our StandWeSpeak/ Mae Expert/Consultancy Service.
                  We will not use Your Personal data for any Direct Marketing
                  without Your Consent. We will not sell Your Personal data to
                  any third party.
                </p>

                <h2>Institution or other Consumer users</h2>
                <p>
                  Corporations, schools, universities, hospitals, insurance,
                  government, NGOs, and other organizations (“Institution(s)”)
                  may introduce StandWeSpeak/Mae Website or chatbot to their
                  employees and members. StandWeSpeak/Mae may carry links to
                  material or Websites or Web Pages or Services managed by third
                  parties on which the Institution and StandWeSpeak may have no
                  control, agreement or affiliation. Please refer to
                  documentation including terms and Privacy Policy provided by
                  such third parties before Using Your Institution Version.
                </p>

                <hr />
                <h2>What Data do We collect and how do We Use it?</h2>
                <h3>
                  1. What data do We collect when working with a
                  StandWeSpeak/Mae Expert/Consultant?
                </h3>
                <p>
                  When You use the StandWeSpeak/Mae Expert/Consultant Service,
                  You get to book a session with a professional. We collect,
                  transmit and securely store these messages in Our servers.{" "}
                  <br />
                  When You Use the StandWeSpeak/Mae Expert/Consultancy Service,
                  You can schedule an online session with Your assigned expert.
                  We collect Your name, email address, contact details, payment
                  details, chosen session dates and time to confirm Your
                  booking.
                  <br />
                  Processing of Your device time zone is based on Our Agreement
                  to calculate Your local date and time so that session bookings
                  are accurately scheduled and for setting accurate session
                  reminder notifications. At times, StandWeSpeak/Mae may get
                  Your local time wrong which could affect the session
                  scheduling. PLEASE ALWAYS VERIFY YOUR LOCAL TIME DISPLAYED BY
                  StandWeSpeak/Mae website IN THE SESSION SCHEDULING SCREEN
                  BEFORE PROCEEDING WITH BOOKING OF A SESSION. If You face any
                  challenge changing Your local time or booking a session,
                  kindly write to Us at
                  <a href="info@standwespeak.com">info@standwespeak.com.</a>
                </p>
                <h3>Information we collect</h3>
                <p>
                  2.1 In the course of your interaction with the Services, we
                  collect the following information:
                  <br />
                  <ul>
                    <li>
                      Information you submit to us. You give us your information
                      by registering your account, accessing website, availing
                      our products and Services. The following is a list of
                      information collected from you when you create and
                      register an account with us:
                    </li>
                    <li>
                      Identification details for the creation of your account;
                    </li>
                    <li>
                      E-mail address: for correspondence (mandatory); account;
                    </li>
                    <li>
                      Financial Information: for the facilitation of payments
                      for Services;
                    </li>
                    <li>Phone number: for correspondence.</li>
                  </ul>
                  <p>2.2 Information we collect automatically</p>
                  <ul>
                    <li>
                      Information on your browser and server logs, including
                      your IP address, the information in the cookies and your
                      browsing history;
                    </li>
                    <li>
                      URL information, time stamp, visit information, your
                      browsing history;
                    </li>
                    <li>
                      Information we collect from third parties: We may work
                      with a limited number of third parties (including mobile
                      network providers, and delivery service providers,
                      collection agencies, analytics providers) to provide the
                      Services and may receive information about you from such
                      third parties. We also receive profile information from
                      the social media platform/s that you use to log in or
                      create accounts with us, as per their terms and policies.
                    </li>
                  </ul>
                </p>
                <h3>Do We use Cookies?</h3>
                <p>
                  We use cookies and/or Web beacons on Our Website. These
                  collect and process data such as browser type, browser
                  language, Operating System, browser data including type and
                  language settings along with the actions You take on Our
                  Website (such as the Web pages viewed and the links clicked.
                  This data is collected after taking required consent from the
                  Users based on Our Cookie Policy. We do not share or sell Your
                  provided data to any third party.
                </p>
                <h3>Do We process Your location data?</h3>
                <p>
                  StandWeSpeak/Mae may infer Your location through Your timezone
                  or other means at a country or state level to provide You
                  appropriate resources.
                </p>
                <h3>
                  How do We use any Third Party Analytics tools and software?
                </h3>
                <p>
                  When You use the Service, StandWeSpeak/Mae usage and system
                  generated event data gets logged and sent to third-party
                  operations and analytics tools such as Google Analytics, Kubit
                  AI and/or Firebase via their secure API integrated within the
                  StandWeSpeak/Mae Website. Any event data sent to third party
                  tools used for operations and analytics is designed to ensure
                  that it is cryptic and does not create a medical or
                  psychological profile of a user in the hands of the processor.{" "}
                  <br />
                  Google Analytics, Firebase Analytics and/or Kubit AI act as
                  Independent Controllers for the data collected by them to
                  provide analytic services. The third party tool APIs also may
                  automatically collect some non-personal events. The use of
                  Google Analytics is governed by Google Data Policy and Data
                  Safeguards . The use of Firebase is governed by Firebase Terms
                  of Service, Use Policy and Crashlytics Terms of Service. The
                  use of Kubit AI is governed by Kubit Terms of Service and
                  privacy Policy. Our Website is hosted on Amazon Web Services,
                  a third party Web management and analytics platform.
                </p>
                <h3>What do We do with Your feedback and ratings?</h3>
                <p>
                  When You Use the Service, You have an option to send Your
                  feedback from within the StandWeSpeak/Mae website/bot.
                  Feedback can be given using the Feedback feature provided in
                  the StandWeSpeak/Mae bot. Personal data, if any provided in
                  Your privately shared feedback messages, will be manually
                  redacted before any processing of Your feedback. We will
                  always take Your explicit consent before revealing Your
                  nickname or name for social proof purposes. AS A BEST
                  PRACTICE, IT IS ADVISED THAT YOU TAKE ADEQUATE PRECAUTIONS TO
                  NOT SHARE YOUR SENSITIVE HEALTH OR PERSONAL DATA WHILE GIVING
                  FEEDBACK OVER EMAIL NETWORKS
                </p>
                <h3>
                  How do We handle Your nickname, age-range, gender, location,
                  race and other related data?
                </h3>
                <p>
                  When You Use the StandWeSpeak/Mae Bot Service, You have the
                  option to provide nickname, age-range, gender, location, race,
                  and other data during Your onboarding. Processing of this
                  age-range data is based on Our Agreement and to understand the
                  profile of Our Users and to help provide them access to tools
                  and techniques or provide other operational Information
                  relevant to their profile.
                </p>
                <h3>
                  How do We handle Your Payment data when You subscribe to Our
                  Services?
                </h3>
                <p>
                  We promote and advertise third-party services on your
                  platform. If You choose to purchase or Use a fee-based Service
                  and pay for such Service by means of web-based purchases, We
                  do not collect, retain and store Your personal, financial and
                  credit/debit card data. This is because Your card settlements
                  including card and personal details will be handled by
                  appropriate third-party payment agencies. We also use other
                  third party payment gateways provided by, RazorPay and/or
                  Stripe for Your web-based purchases. The Payment gateway
                  providers are independent Data Controllers for all the Data
                  they collect from You to process the payment. Please read the
                  payment gateway’s Terms and Privacy Policy before processing
                  Your payment. Processing of this data is for Our Legitimate
                  Interest to support You for any payment or subscription
                  related requests, issues or clarifications.
                </p>
                <h3>
                  What data do We process for the purposes of Our Legitimate
                  Interest?
                </h3>
                <p>
                  We Use Legitimate Interest basis to process Your data in a way
                  which might reasonably be expected as part of running Our
                  business and which does not materially impact Your rights,
                  freedom or interest. When providing Our Services, We may
                  process Your data based on Our Legitimate Interest for the
                  following purposes.
                  <ul>
                    <li>
                      To monitor, detect and deter unauthorized or fraudulent
                      Use of or abuse of the Service;
                    </li>
                    <li>For Uses and disclosures required by law;</li>
                    <li>
                      For disclosures for judicial and administrative
                      proceedings;
                    </li>
                    <li>For disclosures for law enforcement purposes;</li>
                    <li>
                      For Uses and disclosures for public health reporting
                      purposes;
                    </li>
                    <li>
                      For Uses and disclosures to avert a serious threat to
                      health or safety to You, Us, or others;
                    </li>
                    <li>
                      For improving and/or optimizing Our Service quality,
                      safety and performance;
                    </li>
                    <li>
                      To enable Us to troubleshoot and provide customer support,
                      and to respond effectively to Your inquiries and claims;
                    </li>
                    <li>For purposes of research and statistical analysis;</li>
                    <li>
                      For sending limited push notifications such as service
                      information, service reminders and service promotions;
                    </li>
                    <li>
                      To accurately and positively identify Your Personal data
                      at Your request when exercising Your data protection
                      rights;
                    </li>
                    <li>
                      To consider candidates for current and future employment
                      opportunities, and in managing and improving Our
                      recruitment and hiring processes;
                    </li>
                    <li>
                      To help us identify you when you log into the Platform and
                      when you register an account with us;
                    </li>
                    <li>
                      To validate, authorize and map a specific profile with an
                      authorized user;
                    </li>
                    <li>For the performance of a contractual obligation;</li>
                    <li>To communicate with you;</li>
                    <li>
                      To provide you with news, special offers, and general
                      information about other products and services;
                    </li>
                    <li>
                      To provide and process service requests initiated by you.
                    </li>
                  </ul>
                  <h3>
                    Disclosure of your information with third-party services
                  </h3>
                  <ul>
                    <li>
                      We may share your personal information, also other
                      information, with third parties who provide us with
                      infrastructure support services, for meeting the
                      obligations agreed to between you and us. We may also
                      disclose your Personal Information:
                    </li>
                    <li>
                      As required by law, such as to comply with a judicial
                      order, executive orders, requirement by a law enforcement
                      authority, or by other legal processes.
                    </li>
                    <li>
                      When your desired products and services can only be
                      provided if your personal information is disclosed.
                    </li>
                    <li>
                      When we are required to provide information to the
                      companies that provide products or services on our behalf;
                      (unless we notify you otherwise, these companies do not
                      have the right to use your personally identifiable
                      information).
                    </li>
                    <li>
                      When we believe in good faith that disclosure is necessary
                      to protect our rights, protect your safety or the safety
                      of others, or, investigate fraud or crime;
                    </li>
                    <li>
                      If we (or our affiliates) are involved in a merger,
                      acquisition, or sale of all or substantially all of its
                      assets or equity.
                    </li>
                  </ul>
                  <h3>
                    What data do we process when You apply for employment or
                    internship opportunities at StandWeSpeak/Mae?
                  </h3>
                  <p>
                    To process Your application, We ask You to provide Personal
                    Information about Yourself. You provide Your Information
                    voluntarily, and You determine the extent of Information
                    provided to Us. However, some information may be necessary
                    to complete an evaluation of Your application and without it
                    Our ability to consider You as a candidate may be limited.
                    As an applicant, You provide Us with certain Personal
                    Information, including education and employment background,
                    contact information and job qualifications. You also may
                    choose to provide Us with Your CV, resume, or transcripts;
                    employment references and related information; compensation
                    information; and Information about special needs or health
                    conditions and Information relating to accommodations that
                    You may request during the recruiting process. Some personal
                    Information may also include Sensitive Personal Information,
                    for example, government-issued identification number,
                    information relating to a person's race or ethnic origin,
                    political opinions or religious beliefs, physical or mental
                    health or condition, sexual orientation, or memberships. We
                    do not request Sensitive Personal Information for recruiting
                    purposes unless permitted or required by local laws. Where
                    requested, We will obtain Your consent to Our use of Your
                    Sensitive Personal Information. We may also collect
                    Information from Our third party service providers who are
                    lawfully entitled to share Your Information with Us, for
                    example, in connection with a background verification or
                    employment check or an employment reference. Other examples
                    include social media sites (such as LinkedIn). We also take
                    Your consent to carry out background or criminal checks to
                    determine Your suitability for the opportunity.
                    <br />
                    We use Your Personal Information in Our application and
                    recruitment process for purposes including but not limited
                    to:
                  </p>
                  <ul>
                    <li>
                      Assessing your skills, qualifications and interests;
                    </li>
                    <li>
                      Verifying the Information You or others provide and
                      carrying out reference checks or conducting background
                      verification checks;
                    </li>
                    <li>Facilitating the recruiting and interview process;</li>
                    <li>
                      Preparing an offer letter and employment agreement on
                      successful recruitment;
                    </li>
                    <li>Managing and improving our recruiting processes;</li>
                    <li>
                      Producing management reports containing recruiting
                      metrics;
                    </li>
                    <li>
                      Complying with applicable laws, regulations or enforceable
                      governmental requests;
                    </li>
                    <li>
                      Proactively researching your educational and professional
                      background and skills and contacting you if we think you
                      would be suitable for a role with us.
                    </li>
                    <li>
                      Process Your Information to protect Our rights, Our users,
                      applicants, candidates, employees or the public as
                      required or permitted by law.
                    </li>
                  </ul>
                  <p>
                    We process Your Personal Information as necessary to prepare
                    and enter into an employment contract with You. We take
                    appropriate Consent from You wherever required to process
                    Your Sensitive Personal Information. We also process as
                    necessary to comply with Our legal obligations and as
                    necessary for Our legitimate interests. We do not sell Your
                    Information to unauthorized third parties for their
                    marketing purposes. By providing Personal Information, You
                    acknowledge that Your Personal Information may be
                    transferred to or stored in India or in other countries
                    around the world. Such countries may have data protection
                    rules that are different from those of Your country. We take
                    security seriously and use appropriate technical and
                    organizational security measures to safeguard Your
                    Information. But no security procedures are guaranteed to be
                    100% secure so We encourage You to take care when disclosing
                    Personal Information online. We will retain Your Personal
                    Information for as long as required to comply with Our legal
                    obligations, resolve disputes, and to enforce Our
                    contractual agreements, or as necessary for Our legitimate
                    interests, such as to consider You for other current and
                    future employment opportunities with Us. If You are
                    successful in Your application, We retain the Information
                    that You provide during the application process, as part of
                    Your employee records. If You do not want Us to retain Your
                    information for consideration for other roles, or want Us to
                    update it, please contact info@standwespeak.com. Please
                    note, that We may retain some Information if required by law
                    or as necessary to protect Ourselves from legal claims.
                  </p>
                </p>
                <hr />
                <h2>
                  Does StandWeSpeak/Mae Use 3rd party Service providers or
                  agents?
                </h2>
                <p>
                  We have tried to make it as easy as possible for You to have
                  control over Your data. To exercise any of Your rights, You
                  will need to send an email request from Your email ID to
                  info@standwespeak.com. Please note that We may require to
                  verify You before responding to any requests to exercise Your
                  rights. We may also limit Your individual rights requests (a)
                  where denial of access is required or authorized by law; (b)
                  when granting access would have a negative impact on other's
                  privacy; (c) to protect our rights and properties; or (d)
                  where the request is unjustified or excessive.
                </p>
                <hr />
                <h2>Right to rectification and Right to restrict processing</h2>
                <p>
                  You will need to send an email request with reasons from Your
                  email ID to info@standwespeak.com at any time to rectify or
                  restrict processing of Your data basis the Agreement. After
                  verifying You and examining Your request, We will respond to
                  You over email on the action decided and/or taken within one
                  calendar month from verification. We may at times be unable to
                  address Your request, if We are unable to correctly identify
                  You.
                </p>

                <h2>Right of access</h2>
                <p>
                  Due to limited functionality within the Web browser based
                  StandWeSpeak/Mae App, You may not be able to view Your past
                  conversations with the Bot. IF YOU EXERCISE YOUR RIGHT TO BE
                  FORGOTTEN AND RESET YOUR DATA, YOU WILL LOSE THE RIGHT TO
                  ACCESS YOUR DATA AS IT WILL BE PERMANENTLY DELETED.
                </p>
                <h2>Right to Erasure or Right to be Forgotten</h2>
                <p>
                  To erase your data, send us an email at info@standwespeak.com.
                  Your identifiers will be permanently redacted from Our
                  Database. YOU CANNOT REVERSE OR RECOVER YOUR PAST DATA POST A
                  RESET.
                  <br />
                  You will need to send an email request with reasons from Your
                  email ID to info@standwespeak.com, if You have any further
                  questions around Your right to be forgotten. After verifying
                  You and examining Your request, We will respond to You over
                  email on the action decided and/or taken within one calendar
                  month from verification. We may at times be unable to address
                  Your request, if We are unable to correctly identify You.
                </p>
                <h2>Right to withdraw Consent</h2>
                <p>
                  To the extent that the legal basis of Our processing of Your
                  Personal Information is consent, You can withdraw that
                  Consent.
                </p>
                <hr />
                <h2>Can children under 13 use StandWeSpeak/Mae?</h2>
                <p>
                  The StandWeSpeak/Mae Website/Bot is intended for a general
                  audience and is not directed to or intended to be Used by
                  children under the age of thirteen (13) years.
                  <br />
                  We understand the special necessity to protect children's
                  privacy on StandWeSpeak/Mae Website/App, and We do not
                  knowingly collect any Personal data from children.
                  <br />
                  If, however, as a legal Parent or guardian, You believe We
                  have collected any Personal data of Your child, then You will
                  need to send an email request from Your email ID to
                  info@standwespeak.com. After verifying You and examining Your
                  request, We will respond to You over email on the action
                  decided and/or taken within one calendar month from
                  verification. We may at times be unable to address Your
                  request, if We are unable to correctly identify the User. If
                  We have inadvertently collected Personal data from Your child,
                  We will delete any data we may have upon identification and
                  will take reasonable measures to promptly delete such Personal
                  data from Our records.
                  <br />
                  We encourage parents and legal guardians to monitor their
                  children’s Internet usage and to help enforce our Privacy
                  Policy by instructing their children never to provide Personal
                  Data on the Services without their permission. Do not share or
                  Use Your credit/debit card or other payment instrument with
                  Your child to make any website or service purchase.
                  <br />
                </p>
                <hr />
                <h2>
                  Who can You contact for additional questions, comments or
                  concerns?
                </h2>
                <p>
                  For any product, services, subscription, technical or
                  payment-related issues, please contact Us from email ID to
                  info@standwespeak.com with Your questions.
                  <br />
                  Our mail address for all communication is:
                </p>
                <p>
                  304, Amaltas, D.M.Vatika,
                  <br />
                  Shankar Nagar, Raipur,
                  <br />
                  Chhattisgarh, India-492001
                  <br />
                </p>
                <hr />
                <h2>Marketing and Analytics</h2>
                <p>
                  We and our third-party service providers that publish
                  advertisements on our webpages, will set or access website
                  cookies on your computer to distinguish you from other users
                  of the Services. Third-party service providers will use
                  cookies in accordance with their own privacy policies and
                  cannot access cookies used by us. This helps us to provide you
                  with a good experience when you use the Services and also
                  allows us to improve the Services.
                </p>
                <hr />
                <h2>Retention Policy</h2>
                <p>
                  Retention periods for records are based on the type of record,
                  the nature of the activity and product and service that the
                  same is associated with, linked to. The periods for retention
                  of your personal information vary, depending on specific
                  legal, business and statutory requirements.
                </p>
                <hr />
                <h2>Personal Data of Others</h2>
                <p>
                  In some situations, you may provide Personal Information of
                  other individuals (family, friends, likewise) to us. If you
                  provide us with such Personal Information, you represent and
                  warrant that you have obtained their consent for their
                  Personal Information to be collected, used and disclosed as
                  set out in this Privacy Policy.
                </p>
                <hr />
                <h2>Transfer of Information Overseas</h2>
                <p>
                  Your data will not be transferred overseas without your
                  express and specific consent. It will be done only to a
                  country which has an adequate or comparable level of
                  safeguards.
                </p>
                <hr />
                <h2>
                  Can Non-English speaking users use the StandWeSpeak/Mae?
                </h2>
                <p>
                  The StandWeSpeak/Mae has been built and is currently provided
                  only for English language users.
                  <br />
                  To ensure wider reach, StandWeSpeak will, in the near future,
                  launch StandWeSpeak/Mae in other international languages. We
                  will keep You updated of this development
                </p>
                <hr />
                <h2>Changes to this Privacy Policy</h2>
                <p>
                  We may modify Our Privacy Policy from time to time for various
                  reasons including to improve Our privacy practices, to ensure
                  Our Users Right to be Informed, to reflect changes to Our
                  Service, and to comply with relevant laws. If and when this
                  policy is changed, We will post the new notice on our Website
                  and/or as otherwise required by relevant law. It is Your
                  responsibility to check Our Website periodically for updates
                  or changes to Our policy. We encourage You to review changes
                  carefully. If the changes to the Privacy Policy include
                  changes to the collection, storing or processing Your Personal
                  information in a way that infringe into your privacy, we will
                  notify you clearly about the same and/or seek consent where
                  required by the applicable laws and regulations. If You agree
                  to the changes, then please continue to Use Our Service. If
                  You, however, do not agree to any of the changes and You no
                  longer wish to Use Our Service, You may choose to unsubscribe
                  Service. Continuing to Use Our Service after a notice of
                  change has been communicated to You or published on Our
                  Service constitutes Your acceptance of changes and consent to
                  the modified Privacy Policy.
                </p>
                <hr />
                <h2>Severability and Exclusion</h2>
                <p>
                  We have taken every effort to ensure that this Privacy Policy
                  adheres with the applicable laws, including Data Protection
                  Laws. The invalidity or unenforceability of any part of this
                  Privacy Policy shall not prejudice or affect the validity or
                  enforceability of the remainder of this Privacy Policy. This
                  Privacy Policy does not apply to any data other than the data
                  collected by StandWeSpeak while providing the Services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Privacy;
