import React from "react";
import "./style.css";

export default () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div style={{ paddingTop: "30px" }}>
          <h6>JOB DESCRIPTION- RESEARCH ASSOCIATE</h6>
        </div>
        <div className="description">
          <strong>StandWeSpeak:</strong>
          <p>
            StandWeSpeak is a Sex-EdTech initiative that equips people with the
            life-skills required to take care of their sexual health and
            well-being; and empowers them to make informed decisions about their
            bodies, relationships, and mental health throughout their lives.
          </p>
          <p>
            We harness the power of technology to provide individuals of all age
            groups with accurate and age-appropriate information, easy access to
            affordable menstrual hygiene and sexual health products, and
            peer-approved consultancy with gynecologists and counselors to help
            them develop social and sexual relationships based on respect and
            make responsible choices in their future.
          </p>
          <strong>Position Overview:</strong>
          <p>
            We are hereby inviting applications from students and young
            professionals interested in writing research papers, literature
            reviews, case studies, conducting surveys, developing reports,
            conducting case analysis, etc on the topics and issues related to
            sex-ed, sexual wellness products, seeking professional help and much
            more.
          </p>
          <strong>Requirements/Skills:</strong>
          <ul>
            <li>Excellent research, analysis and writing skills.</li>
            <li>
              Experience of independently developing research methodologies and
              delivering project-related research.
            </li>
            <li>Conducting qualitative and quantitative interviews.</li>
            <li>
              Synthesizing large quantities of information from research and
              interviews with the ability to analyze, pick out and prioritize
              key themes and messages.
            </li>
            <li>
              Translating your research and analysis into highly credible,
              impactful written work.
            </li>
            <li>Ability to meet deadlines.</li>
          </ul>
          <strong>
            Location: <span style={{ fontWeight: "lighter" }}>Online.</span>
          </strong>
          <strong>
            Desired start date:{" "}
            <span style={{ fontWeight: "lighter" }}>Immediately.</span>
          </strong>
          <strong>
            Duration of the internship:{" "}
            <span style={{ fontWeight: "lighter" }}>
              1-6 months (Flexible).
            </span>
          </strong>
          <strong>Internship Policy Highlights:</strong>
          <ol>
            <li>
              We recruit exceptional people with outstanding capabilities,
              unfaltering values, and great potential.
            </li>
            <li>
              We are an equal opportunity organization and firmly believe in
              diversity.
            </li>
            <li>Female candidates are strongly encouraged to apply.</li>
          </ol>
          <strong>Desired Values and Mindset:</strong>
          <ol>
            <li>Creativity</li>
            <li>Curiosity</li>
            <li>Empathy</li>
            <li>Integrity</li>
            <li>Leadership</li>
            <li>Professionalism</li>
            <li>Technological Awareness</li>
          </ol>
          <strong>Benefits:</strong>
          <ol>
            <li>Work Experience</li>
            <li>Social Impact</li>
            <li>Professional Growth</li>
            <li>Networking Opportunities</li>
            <li>Recognition</li>
            <li>Satisfaction</li>
          </ol>
          <strong>
            Remuneration:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Pro bono (Voluntary and Unpaid).
            </span>
          </strong>
          <strong>
            Certificates:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to candidates who perform all the assigned tasks within
              the prescribed time limit.
            </span>
          </strong>
          <strong>
            Recommendation Letter:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to only exceptional performers who are associated with us
              for a period of 6 months or more.
            </span>
          </strong>
          <strong style={{ alignSelf: "center", textAlign: "center" }}>
            Click on the link to apply:
            <a
              href="https://forms.gle/j4dUHfFEpxdMHMdeA"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              https://forms.gle/j4dUHfFEpxdMHMdeA
            </a>
          </strong>
          <div className="bottom-border"></div>
        </div>
      </div>
    </React.Fragment>
  );
};
