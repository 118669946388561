import React from "react";
// import BOTLOGO from "../../Assets/Images/BotLogo.jpeg";
import BotLogo from "../../Assets/Chatbot/BotLogo_blankbg.png";
import { connect } from "react-redux";
import { USER_INFO, USER_INFO_RESET } from "../../constant";
import PoweredBy from "../../Assets/Chatbot/poweredBy_6.png";
import sounds from "../../Assets/Sounds/sounds";
import AnalyticsService from "../../Services/Analytics";
import { t } from "i18next";

const SayMore = (props) => {
  // const a = new Audio(sounds);
  // a.play();
  return (
    <div className="bot-container" style={{ justifyContent: "center" }}>
      <div className="bot-icon">
        <img
          src={BotLogo}
          alt="Mae the chatbot"
          className="bot-logo-xl"
          data-aos="fade-in"
          data-aos-delay="40"
          data-aos-duration="1000"
        />
      </div>
      <div className="bot-center-questions">
        <p
          dangerouslySetInnerHTML={{ __html: props.say }}
          data-aos="fade-in"
          data-aos-delay="200"
          data-aos-duration="1000"
        ></p>
      </div>
      <div
        className="bot-center-button"
        onClick={async () => {
          new Audio(sounds.unconvinced).play();
          await AnalyticsService.gaEvent(
            "Chatbot",
            `button clicked`,
            `sure button`,
            1,
          );
          props.sayDone(props.keyName, true, props.chatStore.tempUser);
        }}
        data-aos="fade-in"
        data-aos-delay="400"
        data-aos-duration="1000"
      >
        {t("sure")}!
      </div>
      <div className="bot-powered-by">
        <img
          src={PoweredBy}
          alt="chatbot"
          className="bot-logo-xl cursorOnHover"
          data-aos="fade-in"
          data-aos-duration="1000"
          onClick={() => {
            window.open("https://standwespeak.com/", "_self");
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatStore: state.ChatReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () =>
      dispatch({
        type: USER_INFO_RESET,
      }),
    sayDone: (x, value, tempUser) =>
      dispatch({
        type: USER_INFO,
        payload: { ...tempUser, [x]: value },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SayMore);
