import React, { Component } from "react";
import { FaLinkedin } from "react-icons/fa";
import { LinkedIn } from "react-linkedin-login-oauth2";

class LinkedInButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      errorMessage: "",
    };
  }

  handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
    });
    console.log("LinkedIn Credentials...");
    console.log(data);
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  render() {
    return (
      <LinkedIn
        clientId="86pjbe91amvpmc"
        onFailure={this.handleFailure}
        onSuccess={this.handleSuccess}
        redirectUri="http://localhost:3000/linkedIn"
        scope="r_liteprofile+r_emailaddress"
        disabled={true}
        renderElement={({ onClick, disabled }) => (
          <button
            className="btn rounded-circle shadow text-info grayscale"
            onClick={onClick}
            disabled={disabled}
          >
            <FaLinkedin />
          </button>
        )}
      ></LinkedIn>
    );
  }
}

export default LinkedInButton;
