import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import Heading from "../Reusable/Heading";

//import images
import FamilyIcon from "../../Assets/Images/FamilyIcon.png";
import Assessment from "../../Assets/Images/Assessment.png";
import Charity from "../../Assets/Images/Charity.png";
import crm from "../../Assets/Images/crm.png";
import Equality from "../../Assets/Images/Equality.png";
import Help from "../../Assets/Images/Help.png";
import Pregnant from "../../Assets/Images/Pregnant.png";
import Support from "../../Assets/Images/Support.png";

import "bootstrap/dist/css/bootstrap.min.css";

export default () => {
  const { t } = useTranslation();
  let count = "04";
  let heading = t("about_us_education_title");
  let title = t("about_us_education_body");

  let innerCol = 300;
  let outerCol = 500;

  return (
    <React.Fragment>
      <div id="curriculum" className="outer-container">
        <Heading
          count={count}
          heading={heading}
          title={title}
          titleColor="rgb(97, 97, 97)"
          headingColor="#313131"
          titleMargin="-6%"
        />
        <div
          className="container-fluid"
          style={{ marginTop: "4%", maxWidth: "1130px" }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-1"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #fc5f45" }}
                data-aos="fade-left"
                data-aos-delay={outerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={FamilyIcon}
                  alt="family icon"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_relationship_title")}</Card.Title>
                  <Card.Text>{t("about_us_relationship_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-2"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #38c695" }}
                data-aos="fade-left"
                data-aos-delay={innerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Support}
                  alt="support"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_values_title")}</Card.Title>
                  <Card.Text>{t("about_us_values_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-3"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #b27cf5" }}
                data-aos="fade-right"
                data-aos-delay={innerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Equality}
                  aly="equality"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_gender_title")}</Card.Title>
                  <Card.Text>{t("about_us_gender_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-4"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #feb960" }}
                data-aos="fade-right"
                data-aos-delay={outerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Charity}
                  alt="charity"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_violence_title")}</Card.Title>
                  <Card.Text>{t("about_us_violence_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-5"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #fc5f45" }}
                data-aos="fade-left"
                data-aos-delay={outerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={crm}
                  alt="crm"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_health_title")}</Card.Title>
                  <Card.Text>{t("about_us_health_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-6"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #38c695" }}
                data-aos="fade-left"
                data-aos-delay={innerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Help}
                  alt="help"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_development_title")}</Card.Title>
                  <Card.Text>{t("about_us_development_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-7"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #b27cf5" }}
                data-aos="fade-right"
                data-aos-delay={innerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Assessment}
                  alt="assessment"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_behavior_title")}</Card.Title>
                  <Card.Text>{t("about_us_behavior_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-3 col-md-6" style={{ paddingBottom: "2%" }}>
              <Card
                id="card-8"
                className="card h-100 card-shadow"
                style={{ borderBottom: "3px solid #feb960" }}
                data-aos="fade-right"
                data-aos-delay={outerCol}
              >
                <Card.Img
                  style={{
                    width: "55px",
                    height: "55px",
                    alignSelf: "center",
                    marginTop: "30px",
                  }}
                  variant="top"
                  src={Pregnant}
                  alt="pregnant"
                />
                <Card.Body>
                  <Card.Title>{t("about_us_reproductive_title")}</Card.Title>
                  <Card.Text>{t("about_us_reproductive_body")}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
