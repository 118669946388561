//Abortion-Images
import Safe2Choose from "../../Assets/Images/resources/Safe2Choose.png";
import AbortionPill from "../../Assets/Images/resources/Abortion-Pill.png";
import ReproductiveRights from "../../Assets/Images/resources/Reproductive-Rights.png";

//Contraceptive_Information-Images
import FindMyMethod from "../../Assets/Images/resources/FindMyMethod.png";
import DiseaseCenters from "../../Assets/Images/resources/Centers-for-Disease.png";
import ContraceptionMethods from "../../Assets/Images/resources/Contraception-Methods.png";
import PlannedParenthood from "../../Assets/Images/resources/Planned-Parenthood.png";

//STDs-Images
import STIsTreatment from "../../Assets/Images/resources/STIs-Treatment.png";
import NationalAIDS from "../../Assets/Images/resources/National-AIDS.png";
import AllianceIndia from "../../Assets/Images/resources/Alliance-India.png";
import NationalPortal from "../../Assets/Images/resources/National-Portal.png";

//Violence and safety images
import SheSays from "../../Assets/Images/resources/She-Says.png";
import SnehaMumbai from "../../Assets/Images/resources/Sneha-Mumbai.png";
import NationalCommission from "../../Assets/Images/resources/National-Commission.png";
import TrevorProject from "../../Assets/Images/resources/Trevor-Project.png";

export const categories = [
  {
    name: "Abortion",
    cards: [
      {
        bgSource: Safe2Choose,
        title: "Safe2Choose",
        subtitle: `accurate and tailored information on safe abortion options`,
        link: "https://safe2choose.org/",
      },
      {
        bgSource: AbortionPill,
        title: "How to use Abortion Pill",
        subtitle: `Safe medical abortion instructions`,
        link: "https://howtoUseAbortionPill.org/",
      },
      {
        bgSource: ReproductiveRights,
        title: "Centre for Reproductive Rights",
        subtitle: `legal status of abortion in countries across the globe`,
        link: "https://maps.reproductiverights.org/worldabortionlaws?category[294]=294",
      },
    ],
  },
  {
    name: "Contraceptive Information",
    cards: [
      {
        bgSource: FindMyMethod,
        title: " FindMyMethod",
        subtitle: `Comprehensive, easy-to-understand and multilingual contraceptive information.`,
        link: "https://findmymethod.org/",
      },
      {
        bgSource: DiseaseCenters,
        title: "Centers for Disease Control and Prevention",
        subtitle: `Information, technical assistance and consultation on reproductive health.`,
        link: "https://www.cdc.gov/reproductivehealth/contraception/index.htm",
      },
      {
        bgSource: ContraceptionMethods,
        title: "Contraception Methods",
        subtitle: `A resource link on Contraception`,
        link: "https://medlineplus.gov/birthcontrol.html",
      },
      {
        bgSource: PlannedParenthood,
        title: "Planned Parenthood",
        subtitle: `Find your best birth control method`,
        link: "https://www.plannedparenthood.org/learn/birth-control",
      },
    ],
  },
  {
    name: "STDs",
    cards: [
      {
        bgSource: STIsTreatment,
        title: "Centers for Disease Control and Prevention",
        subtitle: `STIs Treatment Guidelines`,
        link: "https://www.cdc.gov/std/",
      },
      {
        bgSource: NationalAIDS,
        title: "National AIDS Control Organisation",
        subtitle: `accurate, complete and consistent information about HIV.`,
        link: " http://naco.gov.in/",
      },
      {
        bgSource: AllianceIndia,
        title: "Alliance India",
        subtitle: `effective, innovative, community-based HIV programmes`,
        link: "https://allianceindia.org/about-us/",
      },
      {
        bgSource: NationalPortal,
        title: "National Portal of India",
        subtitle: `India Against AIDS`,
        link: "https://www.india.gov.in/spotlight/india-against-aids-aspiring-aids-free-india",
      },
    ],
  },
  {
    name: "Violence and safety",
    cards: [
      {
        bgSource: SheSays,
        title: "She Says",
        subtitle: `Laws regarding Violence and Safety in India`,
        link: "https://www.shesays.in/",
      },
      {
        bgSource: SnehaMumbai,
        title: "Sneha Mumbai",
        subtitle: `Domestic violence- seeking help for physical, emotional, sexual or financial abuse.`,
        link: "https://snehamumbai.org/domestic-violence/",
      },
      {
        bgSource: NationalCommission,
        title: "National Commission for Women- India",
        subtitle: `laws related to Women`,
        link: "http://ncw.nic.in/important-links/List-of-Laws-Related-to-Women",
      },
      {
        bgSource: TrevorProject,
        title: "The Trevor Project",
        subtitle: `Crisis intervention and suicide prevention services for LGBTQ+ people under 25.`,
        link: "https://www.thetrevorproject.org/about/",
      },
    ],
  },
];
