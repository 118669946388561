import React from "react";
import "./style.css";

export default () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div style={{ paddingTop: "30px" }}>
          <h6>JOB DESCRIPTION- GRAPHIC DESIGNER</h6>
        </div>
        <div className="description">
          <strong>StandWeSpeak:</strong>
          <p>
            StandWeSpeak is a Sex-EdTech initiative that equips people with the
            life-skills required to take care of their sexual health and
            well-being; and empowers them to make informed decisions about their
            bodies, relationships, and mental health throughout their lives.
          </p>
          <p>
            We harness the power of technology to provide individuals of all age
            groups with accurate and age-appropriate information, easy access to
            affordable menstrual hygiene and sexual health products, and
            peer-approved consultancy with gynecologists and counselors to help
            them develop social and sexual relationships based on respect and
            make responsible choices in their future.
          </p>
          <strong>Position Overview:</strong>
          <p>
            Forward-thinking, fast-paced company looking to hire an intern with
            strong knowledge and understanding of the digital media landscape,
            including various social media websites. Our company is currently
            kickstarting social media presence. Candidate needs to have a “do
            what it takes” attitude. A proven track record in social media would
            be desirable.
          </p>
          <strong>Responsibilities:</strong>
          <ul>
            <li>
              Content creation, engagement and promotion on social media
              platforms- Facebook, Instagram, Twitter and LinkedIn.
            </li>
            <li>
              Grow online social networks by increasing fan-base and
              interactions.
            </li>
            <li>
              Monitoring and replying to comments and tweets from potential
              clients and customers.
            </li>
            <li>
              Using polls and surveys to build our social media footprint and
              reputation.
            </li>
            <li>Ensure consistent campaigns and relative content.</li>
            <li>
              Review data on the performance of social media platforms and
              adjust plans or strategies to optimize outreach.
            </li>
            <li>
              Performs research to find articles, stories, resources or other
              relevant content.
            </li>
          </ul>
          <strong>Requirements/Skills:</strong>
          <ul>
            <li>
              Social media marketing, online engagement, strong verbal
              communication.
            </li>
            <li>
              Creative, highly motivated and independent individual, who is
              comfortable with both taking initiative and working in
              collaboration.
            </li>
            <li>
              Strong communication, writing skills, design skills and out of the
              box thinking to promote products and services of StandWeSpeak in a
              unique and creative way.
            </li>
            <li>
              Experience with basic photo editing, post designing and video
              editing, is necessary.
            </li>
            <li>
              Familiarity with mainstream social media platforms, including but
              not limited to Facebook, Twitter, Instagram, Linkedin etc.
            </li>
          </ul>
          <strong>
            Location: <span style={{ fontWeight: "lighter" }}>Online.</span>
          </strong>
          <strong>
            Desired start date:{" "}
            <span style={{ fontWeight: "lighter" }}>Immediately.</span>
          </strong>
          <strong>
            Duration of the internship:{" "}
            <span style={{ fontWeight: "lighter" }}>
              1-6 months (Flexible).
            </span>
          </strong>
          <strong>Internship Policy Highlights:</strong>
          <ol>
            <li>
              We recruit exceptional people with outstanding capabilities,
              unfaltering values, and great potential.
            </li>
            <li>
              We are an equal opportunity organization and firmly believe in
              diversity.
            </li>
            <li>Female candidates are strongly encouraged to apply.</li>
          </ol>
          <strong>Desired Values and Mindset:</strong>
          <ol>
            <li>Creativity</li>
            <li>Curiosity</li>
            <li>Empathy</li>
            <li>Integrity</li>
            <li>Leadership</li>
            <li>Professionalism</li>
            <li>Technological Awareness</li>
          </ol>
          <strong>Benefits:</strong>
          <ol>
            <li>Work Experience</li>
            <li>Social Impact</li>
            <li>Professional Growth</li>
            <li>Networking Opportunities</li>
            <li>Recognition</li>
            <li>Satisfaction</li>
          </ol>
          <strong>
            Remuneration:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Pro bono (Voluntary and Unpaid).
            </span>
          </strong>
          <strong>
            Certificates:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to candidates who perform all the assigned tasks within
              the prescribed time limit.
            </span>
          </strong>
          <strong>
            Recommendation Letter:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to only exceptional performers who are associated with us
              for a period of 6 months or more.
            </span>
          </strong>
          <strong style={{ alignSelf: "center", textAlign: "center" }}>
            Click on the link to apply:
            <a
              href="https://forms.gle/4VYd8vzmHA7Mh5icA"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              https://forms.gle/4VYd8vzmHA7Mh5icA
            </a>
          </strong>
          <div className="bottom-border"></div>
        </div>
      </div>
    </React.Fragment>
  );
};
