/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { USER_INFO, USER_INFO_RESET } from "../../constant";
import { USER_AGE_TYPES } from "../../types/chatbot";
import Swal from "sweetalert2";
import sounds from "../../Assets/Sounds/sounds";
import user from "../../Services/user";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export const ChoiceQuestion = (props) => {
  const { t } = useTranslation();

  const [selectedChoice, setSelectedChoice] = useState(
    props.multiple ? [] : "",
  );
  const selectedChoice1 = useRef(props.multiple ? [] : "");
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const [windowDimensions, setWindowDimensions] = useState(
    user.getWindowDimensions(),
  );

  const enterFunction = (e) => {
    // console.log("selected ", selectValue.current.value);
    if (e.key === "Enter") {
      props.addChoice(
        props.choiceName,
        selectedChoice1.current,
        props.chatStore.tempUser,
      );
      setSelectedChoice("");
      selectedChoice1.current = "";
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(user.getWindowDimensions());
    }
    if (window) {
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", enterFunction, false);
    return () => {
      document.removeEventListener("keyup", enterFunction, false);
    };
  }, [selectedChoice]);

  useEffect(() => {
    if (props.chatStore.tempUser.age) {
      window.instances = tippy(document.querySelectorAll(".bot-ask-choice"), {
        placement: "bottom",
        appendTo: () => document.body,
        content: (reference) => {
          const title = reference.getAttribute("title");
          reference.removeAttribute("title");
          return title;
        },
        theme: "mae-tippy",
      });
    }
  }, [props.chatStore.tempUser.age]);

  useEffect(() => {
    if (props.chatStore.tempUser.gender) {
      if (window.instances && window.instances.length) {
        window.instances.forEach((instance) => {
          instance.destroy();
        });
      }
    }
  }, [props.chatStore.tempUser.gender]);

  return (
    <div id="bot-container" className="bot-container">
      <div
        className="bot-questions"
        data-aos="fade-in"
        data-aos-delay="40"
        data-aos-duration="1000"
      >
        {" "}
        {props.choiceQuestion.split("\n").map((str, i) => (
          <div key={i}>{str}</div>
        ))}
      </div>
      <p
        data-aos="fade-in"
        data-aos-delay="40"
        data-aos-duration="1000"
        data-tippy-content={"Nooooooo!"}
      >
        {props.multiple
          ? "Select all that apply"
          : "Select one option from the list"}
      </p>
      <div
        className="bot-ask-choices"
        style={{ width: props.width || "40%" }}
        data-aos="fade-in"
        data-aos-delay="400"
        data-aos-duration="1000"
      >
        {props.choiceAnswers.length > 0 &&
          props.choiceAnswers.map((answer, i) => {
            const aboutOption =
              props.infoAbout?.filter((x) => x.title === answer)?.[0]?.body ||
              answer;
            if (props.multiple) {
              return (
                <div
                  key={i}
                  title={aboutOption}
                  className={
                    "bot-ask-choice " +
                    (selectedChoice.includes(answer) ? "active" : "")
                  }
                  onClick={() => {
                    if (!selectedChoice.includes(answer)) {
                      new Audio(sounds.pullout).play();
                      setSelectedChoice((x) => [...x, answer]);
                      selectedChoice1.current = [
                        ...selectedChoice1.current,
                        answer,
                      ];
                    } else {
                      let x = selectedChoice.filter((x) => x !== answer);
                      setSelectedChoice(x);
                      selectedChoice1.current = selectedChoice1.current.filter(
                        (x) => x !== answer,
                      );
                    }
                  }}
                >
                  {answer}
                </div>
              );
            } else {
              return (
                <div
                  key={i}
                  className={
                    "bot-ask-choice " +
                    (selectedChoice === answer ? "active" : "")
                  }
                  onClick={() => {
                    new Audio(sounds.pullout).play();
                    setSelectedChoice(answer);
                    selectedChoice1.current = answer;
                  }}
                >
                  {answer}
                </div>
              );
            }
          })}
        {windowDimensions.width > 640 ? (
          <>
            <div
              className="bot-prev-step"
              onClick={() => {
                new Audio(sounds.unconvinced).play();
                props.goBack();
              }}
            >
              <FaChevronLeft size={80} />
            </div>
            <div
              className="bot-next-step"
              onClick={() => {
                props.addChoice(
                  props.choiceName,
                  selectedChoice,
                  props.chatStore.tempUser,
                );
                setSelectedChoice("");
                selectedChoice1.current = "";
              }}
            >
              <FaChevronRight size={80} />
            </div>
          </>
        ) : (
          <div className="choices-next-buttons">
            <div
              className="bot-next-button choices-next-button"
              onClick={() => {
                props.addChoice(
                  props.choiceName,
                  selectedChoice,
                  props.chatStore.tempUser,
                );
                setSelectedChoice("");
                selectedChoice1.current = "";
              }}
            >
              Next <FaChevronRight size={24} style={{ marginLeft: 12 }} />
            </div>
          </div>
        )}
        <p style={{ color: "#D9D3F8", marginTop: 8, width: "100%" }}>
          {t("enter_for_next")}
        </p>
      </div>

      {props.infoAbout && props.infoAbout.length > 0 && (
        <>
          <div className="show-more-info-button" onClick={() => handleShow()}>
            Show more information about this
          </div>
          <Modal
            show={show}
            size={"xl"}
            scrollable={true}
            onHide={() => setShow(false)}
            contentClassName="info-modal-container"
          >
            <Modal.Header closeButton>
              <Modal.Title>What do these mean</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="scroll-down-container"
                onClick={() => {
                  if (document.getElementsByClassName("modal-body")) {
                    document.getElementsByClassName("modal-body")[0].scrollTo({
                      top: document.getElementsByClassName("modal-body")[0]
                        .scrollHeight,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                <FaChevronDown />
              </div>
              {props.infoAbout &&
                props.infoAbout.length > 0 &&
                props.infoAbout.map((v, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        padding: "2%",
                      }}
                    >
                      <h5>{v.title}</h5>
                      <p>{v.body}</p>
                    </div>
                  );
                })}
            </Modal.Body>
          </Modal>
        </>
      )}

      <div className="more-info-question"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatStore: state.ChatReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () =>
      dispatch({
        type: USER_INFO_RESET,
      }),
    addChoice: async (x, value, tempUser) => {
      if (x === "age" && value === USER_AGE_TYPES.age_below_13) {
        Swal.fire({
          icon: "error",
          title: "Hey there!",
          text: `StandWeSpeak/Mae is not to be used by children under 13 years.`,
          confirmButtonColor: "#68548D",
        });
        return;
      }
      if (x === "age" && value === USER_AGE_TYPES.age_13_to_15) {
        Swal.fire({
          icon: "warning",
          title: "Hey there!",
          text: `If You are less than 18 years of age, please read through the StandWeSpeak Privacy Policy and the StandWeSpeak Terms of Service with Your parent or legal guardian to understand eligibility before use.`,
          confirmButtonColor: "#68548D",
          confirmButtonText: "Close",
        });
      }
      if (value.length) {
        new Audio(sounds.unconvinced).play();
        dispatch({
          type: USER_INFO,
          payload: { ...tempUser, [x]: value },
        });
      } else {
        new Audio(sounds.alertGesture).play();
        Swal.fire({
          icon: "info",
          title: "Hey there!",
          text: `It seems like you forgot to tell me about your ${x}. Knowing you better helps me answer your questions accurately. 😊`,
          confirmButtonColor: "#68548D",
        });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceQuestion);
