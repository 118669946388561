import React from "react";
import "./style.css";

export default () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div style={{ paddingTop: "30px" }}>
          <h6>JOB DESCRIPTION- CONTENT WRITER</h6>
        </div>
        <div className="description">
          <strong>StandWeSpeak:</strong>
          <p>
            StandWeSpeak is a Sex-EdTech initiative that equips people with the
            life-skills required to take care of their sexual health and
            well-being; and empowers them to make informed decisions about their
            bodies, relationships, and mental health throughout their lives.
          </p>
          <p>
            We harness the power of technology to provide individuals of all age
            groups with accurate and age-appropriate information, easy access to
            affordable menstrual hygiene and sexual health products, and
            peer-approved consultancy with gynecologists and counselors to help
            them develop social and sexual relationships based on respect and
            make responsible choices in their future.
          </p>
          <strong>Position Overview:</strong>
          <p>
            We are looking to hire dedicated Content Writers to create content
            for blogs, articles, product descriptions, social media, and/or the
            company website. We’re looking for candidates who will help us
            expand our digital footprint and drive more value through online
            content.
          </p>
          <strong>Requirements/Skills:</strong>
          <ul>
            <li>
              Proven work experience as a content writer or a similar role.
            </li>
            <li>Portfolio of published articles.</li>
            <li>Experience in doing research using multiple sources.</li>
            <li>Familiarity with web publications.</li>
            <li>Excellent writing and editing skills (English).</li>
            <li>Adept in Design skills.</li>
            <li>
              Hands-on experience with editing tools and software but not
              necessary.
            </li>
            <li>Ability to meet deadlines</li>
          </ul>
          <strong>
            Topics:{" "}
            <span style={{ fontWeight: "lighter" }}>
              The broad domain we cover (but not limited to) for blogs and
              articles are-
            </span>
          </strong>
          <p>
            <span style={{ fontWeight: "bold" }}>Intersectionality</span> (such
            as body image, sexual health, sex and sexuality, LGBTQIA+,
            disability and mental health, violence, gender, masculinity and
            privilege, misogyny, religion & sex-ed, rural and urban communities,
            etc.), <span style={{ fontWeight: "bold" }}>Interviews</span>{" "}
            (individuals who have done groundbreaking work in the field of
            gender, sexuality, mental health, sexual health rights and the likes
            to get an insight into their lives and their work),{" "}
            <span style={{ fontWeight: "bold" }}>
              Future of Sex and technology
            </span>{" "}
            (such as the use of VR, advancement in sexual health toys, etc.),
            <span style={{ fontWeight: "bold" }}>
              News, events, updates
            </span>{" "}
            (the on-going trends and inventions in the field of sexual health,
            mental health, sexuality education or sex-tech),{" "}
            <span style={{ fontWeight: "bold" }}>History</span> (the history and
            evolution of sex-ed and sex-tech in India and globally){" "}
            <span style={{ fontWeight: "bold" }}>Mental Health</span> (such as
            the effect of poor sexual health and well-being on the mental health
            of a person and vice-versa){" "}
            <span style={{ fontWeight: "bold" }}>Society</span> (the current and
            changing perception of society regarding the importance of sexual
            health and well-being.){" "}
            <span style={{ fontWeight: "bold" }}>Videos</span> (would like to
            create a video story? Sure){" "}
            <span style={{ fontWeight: "bold" }}>Media Interpretation</span>{" "}
            (misrepresentation of women, gender or gender-based violence in
            mainstream media and/or popular media, etc).
          </p>
          <strong>
            Location: <span style={{ fontWeight: "lighter" }}>Online.</span>
          </strong>
          <strong>
            Desired start date:{" "}
            <span style={{ fontWeight: "lighter" }}>Immediately.</span>
          </strong>
          <strong>
            Duration of the internship:{" "}
            <span style={{ fontWeight: "lighter" }}>
              1-6 months (Flexible).
            </span>
          </strong>
          <strong>Internship Policy Highlights:</strong>
          <ol>
            <li>
              We recruit exceptional people with outstanding capabilities,
              unfaltering values, and great potential.
            </li>
            <li>
              We are an equal opportunity organization and firmly believe in
              diversity.
            </li>
            <li>Female candidates are strongly encouraged to apply.</li>
          </ol>
          <strong>Desired Values and Mindset:</strong>
          <ol>
            <li>Creativity</li>
            <li>Curiosity</li>
            <li>Empathy</li>
            <li>Integrity</li>
            <li>Leadership</li>
            <li>Professionalism</li>
            <li>Technological Awareness</li>
          </ol>
          <strong>Benefits:</strong>
          <ol>
            <li>Work Experience</li>
            <li>Social Impact</li>
            <li>Professional Growth</li>
            <li>Networking Opportunities</li>
            <li>Recognition</li>
            <li>Satisfaction</li>
          </ol>
          <strong>
            Remuneration:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Pro bono (Voluntary and Unpaid).
            </span>
          </strong>
          <strong>
            Certificates:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to candidates who perform all the assigned tasks within
              the prescribed time limit.
            </span>
          </strong>
          <strong>
            Recommendation Letter:{" "}
            <span style={{ fontWeight: "lighter" }}>
              Awarded to only exceptional performers who are associated with us
              for a period of 6 months or more.
            </span>
          </strong>
          <strong style={{ alignSelf: "center", textAlign: "center" }}>
            Click on the link to apply:
            <a
              href="https://forms.gle/4iZLrUzzSWBTFKuP9"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              https://forms.gle/4iZLrUzzSWBTFKuP9
            </a>
          </strong>
          <div className="bottom-border"></div>
        </div>
      </div>
    </React.Fragment>
  );
};
