/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./style.css";
import { RiSearch2Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useTranslation } from "react-i18next";

export default ({ searchTerm, placeholder, removeIcon = false, cssStyle }) => {
  let txtElements;
  let wordss;
  let txt;
  let wordIndex;
  let waits;
  let isDeleting;

  const { t } = useTranslation();

  // const [dataWords, setDataWords] = useState();

  const [SearchInput, setSearchInput] = useState("");

  const typeWriter = function (txtElement, words, wait = 3000) {
    txtElements = txtElement;
    wordss = words;
    txt = "";
    wordIndex = 0;
    waits = parseInt(wait, 10);
    type();
    isDeleting = false;
  };

  const type = function () {
    //current index of word
    const current = wordIndex % wordss.length;
    //Get full text of current word
    const fullTxt = wordss[current];

    // console.log(fullTxt);

    //check if deleting
    if (isDeleting) {
      //Remove char
      txt = fullTxt.substring(0, txt.length - 1);
    } else {
      //Add char
      txt = fullTxt.substring(0, txt.length + 1);
    }

    //Insert txt into element
    // txtElements.innerHTML = `<span className="txt"> ${txt}</span>`
    // txtElements.placeholder = `Ask me about ${txt}`;
    txtElements.innerHTML = `${txt}`;

    //Initial type speed
    let typeSpeed = 50;

    if (isDeleting) {
      typeSpeed /= 2;
    }

    //if word is complete
    if (!isDeleting && txt === fullTxt) {
      //Make pause at end
      typeSpeed = waits;
      //Set delete to true
      isDeleting = true;
    } else if (isDeleting && txt === "") {
      isDeleting = false;
      //Move to next word
      wordIndex++;
      //Pause before start typing
      typeSpeed = 500;
    }

    window.timeOut = setTimeout(() => type(), typeSpeed);
  };

  useEffect(() => {
    if (window.timeOut) clearTimeout(window.timeOut);
    const txtElement = document.querySelector(".txt-type");
    const words = t("search_bar_words", { returnObjects: true });
    const wait = txtElement.getAttribute("data-wait");
    new typeWriter(txtElement, words, wait);
  }, [t]);

  return (
    <React.Fragment>
      <label className="input-label" style={{ position: "relative" }}>
        <input
          placeholder={placeholder || t("ask_me_about")}
          className="faq-search-input"
          value={SearchInput}
          style={{ ...cssStyle, paddingLeft: removeIcon ? "0px" : "85px" }}
          onChange={(e) => {
            setSearchInput(e.target.value);
            searchTerm(e.target.value.toLowerCase().trim());
          }}
        />
        {!removeIcon && (
          <IconContext.Provider value={{ size: "50px", color: "#7d84c4" }}>
            <RiSearch2Line className="search-icon" />
          </IconContext.Provider>
        )}
        <span
          style={{
            display: SearchInput ? "none" : "inherit",
            left: removeIcon ? "40%" : "50%",
          }}
          className="txt-type"
          data-wait="2000"
        ></span>
      </label>
    </React.Fragment>
  );
};
