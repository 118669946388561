import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Swal from "sweetalert2";
import { USER_INFO, USER_INFO_RESET } from "../../constant";
import sounds from "../../Assets/Sounds/sounds";
import AnalyticsService from "../../Services/Analytics";
import user from "../../Services/user";
import "aos/dist/aos.css";

const InputQuestion = (props) => {
  const { t } = useTranslation();

  const inputValue = useRef("");
  const [windowDimensions, setWindowDimensions] = useState(
    user.getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(user.getWindowDimensions());
    }
    if (window) {
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bot-container">
      <div
        className="bot-questions"
        data-aos="zoom-in"
        dangerouslySetInnerHTML={{ __html: props.inputQuestion }}
      ></div>
      <p></p>
      <div className="bot-input-container">
        <input
          ref={inputValue}
          className="bot-input"
          type="text"
          maxLength="40"
          placeholder={"Enter " + props.inputName}
          data-aos="fade-in"
          data-aos-duration="200"
          autoFocus
          required
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              props.addInput(
                props.inputName,
                inputValue.current.value,
                props.chatStore.tempUser,
              );
            }
          }}
        />
        <p style={{ color: "#D9D3F8", marginTop: 8 }}>{t("enter_for_next")}</p>
        {windowDimensions.width > 640 ? (
          <>
            <div
              className="bot-prev-step"
              onClick={() => {
                new Audio(sounds.unconvinced).play();
                props.goBack();
              }}
            >
              <FaChevronLeft size={80} />
            </div>
            <div
              className="bot-next-step"
              onClick={() =>
                props.addInput(
                  props.inputName,
                  inputValue.current.value,
                  props.chatStore.tempUser,
                )
              }
            >
              <FaChevronRight size={80} />
            </div>
          </>
        ) : (
          <div
            className="bot-next-button"
            onClick={() =>
              props.addInput(
                props.inputName,
                inputValue.current.value,
                props.chatStore.tempUser,
              )
            }
          >
            Next <FaChevronRight size={24} style={{ marginLeft: 12 }} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatStore: state.ChatReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () =>
      dispatch({
        type: USER_INFO_RESET,
      }),
    addInput: async (x, value, tempUser) => {
      if (value) {
        new Audio(sounds.unconvinced).play();
        await AnalyticsService.gaEvent(
          "Chatbot",
          `entered user name`,
          `${value}`,
          1,
        );
        dispatch({
          type: USER_INFO,
          payload: { ...tempUser, [x]: value },
        });
      } else {
        new Audio(sounds.alertGesture).play();
        Swal.fire({
          icon: "info",
          title: "Hey there!",
          text: `It seems like you forgot to tell me about your ${x}. Knowing you better helps me answer your questions accurately. 😊`,
        });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputQuestion);
