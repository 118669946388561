import { CHATBOT_TOPICS } from "../../types/chatbot";

// Menstruation
const productMenstruation = [
  {
    pid: 1,
    product_title: "Menstrual Cup",
    product_subtitle: "Sirona",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/menstrual-cup-sirona.jpg",
    product_url:
      "https://www.thesirona.com/products/sirona-reusable-menstrual-cup-for-women-medium?variant=37152483311813&currency=INR&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic&gclid=CjwKCAiAqIKNBhAIEiwAu_ZLDo_GE9CxNUUMrSHQfTavQAvEJDW80YMhSQIwwon_ycwJRiOOP5KvqxoCnaAQAvD_BwE",
  },
  {
    pid: 2,
    product_title: "Soothing Stick: Period Cramp Care",
    product_subtitle: "That Sassy Thing",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Sassy-Period+Cramp+Relief.jpg",
    product_url:
      "https://thatsassything.com/collections/sexual-and-menstrual-wellness/products/period-roll-on",
  },
  {
    pid: 3,
    product_title: "Tampons with Applicator",
    product_subtitle: "The Woman's Company",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/Tampons+with+applicator-+the+womans+company.png",
    product_url:
      "https://www.thewomanscompany.com/products/tampons-with-applicators",
  },
  {
    pid: 4,
    product_title: "Period Underwear",
    product_subtitle: "Safecup",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/Safecup-+period+underwear.jpg",
    product_url: "https://safecup.co/products/crimson-underwear",
  },
  {
    pid: 5,
    product_title: "High-waist leakproof underwear",
    product_subtitle: "Soqo",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/Soqo.png",
    product_url: "https://soqo.in/products/period-underwear",
  },
  {
    pid: 6,
    product_title: "Panty Liners",
    product_subtitle: "The Woman's Company",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/panty+liners-+the+womans+company.png",
    product_url: "https://www.thewomanscompany.com/products/panty-liners",
  },
  {
    pid: 7,
    product_title: "Period Underwear",
    product_subtitle: "Unmoda Global",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Period+Underwear+(1).jpg",
    product_url:
      "https://www.unmodaglobal.com/collections/all/products/women-hipster-organic-cotton-period-underwear",
  },
  {
    pid: 8,
    product_title: "Heat Patches for Period Pain",
    product_subtitle: "Nua",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/Nua-heat+patch.webp",
    product_url: "https://nuawoman.com/heat-patch-cramp-comfort.html",
  },
  {
    pid: 9,
    product_title: "Mini Tampons",
    product_subtitle: "Lemme Be",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Teen+Tampons.jpg",
    product_url: "https://lemmebe.com/products/mini-tampons-8-organic-tampons",
  },
  {
    pid: 10,
    product_title: "BREASE - Bra-Stress Relief",
    product_subtitle: "Carmesi",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/brease-Carmesi.png",
    product_url: "https://mycarmesi.com/products/brease-bra-stress-relief-roll",
  },
];

// $in: ["Sex, Consent and Relationships","STDs, Pregnancy and Contraception","Abortion & Health Services","Violence and Safety"]
const productContraception = [
  {
    pid: 1,
    product_title: "Dotted Condoms ",
    product_subtitle: "Bleu Cares",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Bleu+Condoms.jpg",
    product_url:
      "https://bleucares.com/products/dotted-condoms-pack-of-8-units",
  },
  {
    pid: 2,
    product_title: "Domina Female Condom",
    product_subtitle: "Peesafe",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Domina+Female+Condoms.png",
    product_url: "https://www.peesafe.com/products/pee-safe-domina-pack-of-2",
  },
  {
    pid: 3,
    product_title: "Classic Salad (Pack of 10)",
    product_subtitle: "Salad",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/Salad_Condom_Packaging_front_900x.jpg",
    product_url: "https://www.thesalad.org/products/classic",
  },
  {
    pid: 4,
    product_title: "Ultra Thin Long Last Condoms",
    product_subtitle: "Bold Care ",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/BoldCare-+condoms.jpg",
    product_url:
      "https://shop.mensxp.com/bold-care-ultra-thin-long-last-condoms-pack-of-10-p735439?utm_campaign=Smart-Shopping-Campaign-Medium-CR&utm_device=c&utm_term=&network=u&utm_medium=cpc&utm_source=google&agid=128059523225&ap=&aoi=&ci=14997754688&cre=554129760399&fid=&lop=21268&ma=&mo=&pl=&ti=pla-1402299943139&gclid=CjwKCAiAqIKNBhAIEiwAu_ZLDp4VpENpvNgshjhkMDcvZ2_jfhc_IhviFaKNmegKYq_O-vLP0Ip10hoCxiAQAvD_BwE",
  },
  {
    pid: 5,
    product_title: "DIY Salad Kit",
    product_subtitle: "Salad",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/DIY+Salad+Kit-+Salad.jpg",
    product_url:
      "https://www.thesalad.org/products/diy-salad-kit-limited-edition",
  },
  {
    pid: 6,
    product_title: "I Pill Tab",
    product_subtitle: "PharmEasy",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/i-pill-tab-pharmaeasy.webp",
    product_url: "https://pharmeasy.in/health-care/products/i-pill-tab-22168",
  },
  {
    pid: 7,
    product_title: "I Can Pregnancy Test Kit",
    product_subtitle: "Piramal Healthcare",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/ICAN+Pregnancy.jpg",
    product_url:
      "https://pharmeasy.in/health-care/products/i-can-pregnancy-test-kit-88380?&utm_source=google&utm_medium=cpc&utm_campaign=Shopping_OTC_Cat_FP_Sexual_Wellness&utm_adgroup=&gclid=CjwKCAjw7rWKBhAtEiwAJ3CWLGG1Wb2PSneL3bjnB9gYKq3wmjdDQLRIyHk9dx6wlCdyjMytGwzYohoC02sQAvD_BwE",
  },
  {
    pid: 8,
    product_title: "I know ovulation test kit",
    product_subtitle: "Shycart",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/i-know+Ovulation-Test-Kits-+Shykart.png",
    product_url: "https://www.shycart.com/i-know-ovulation-test-kit",
  },
  // {
  //   pid: 9,
  //   product_title: "I Can Pregnancy Test Kit",
  //   product_subtitle: "Piramal Healthcare",
  //   product_image:
  //     "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/ICAN+Pregnancy.jpg",
  //   product_url:
  //     "https://www.apollopharmacy.in/otc/i-can-pregnancy-test-device",
  // },
];

// // Masturbation and Pleasure
// const productPleasure = [
//   {
//     pid: 1,
//     product_title: "Glide",
//     product_subtitle: "My Muse",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/glide-muse.jpg",
//     product_url:
//       "https://mymuse.in/collections/all/products/glide-water-based-lubricant",
//   },
//   {
//     pid: 2,
//     product_title: "DTF: Sexual Lube",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/sexual+lube-DTf-+sassy+thing.jpg",
//     product_url:
//       "https://thatsassything.com/collections/get-off-40-and-50-discount/products/sexual-lube",
//   },
//   {
//     pid: 3,
//     product_title: "Satisfyer",
//     product_subtitle: "IMBesharam",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Satisfyer.jpg",
//     product_url:
//       "https://in.imbesharam.com/collections/vibrators/products/satisfyer-layons-clitoral-stimulator-in-a-bridal-gown",
//   },
//   {
//     pid: 4,
//     product_title: "Pulse",
//     product_subtitle: "My Muse",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/Pulse-muse.jpg",
//     product_url:
//       "https://mymuse.in/collections/all/products/pulse-full-body-massager",
//   },
//   {
//     pid: 5,
//     product_title: "OG",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/OG-+that+sassy+thing.png",
//     product_url:
//       "https://thatsassything.com/collections/products-bundles/products/og",
//   },
//   {
//     pid: 6,
//     product_title: "Lit",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/Lit-That+Sassy+Thing.png",
//     product_url:
//       "https://thatsassything.com/collections/products-bundles/products/lit",
//   },
//   {
//     pid: 7,
//     product_title: "The slip & slide kit",
//     product_subtitle: "My Muse",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/SlipandSlideKit-My+muse.jpg",
//     product_url:
//       "https://mymuse.in/collections/all/products/couple-gift-for-anniversary-the-slip-slidekit",
//   },
//   {
//     pid: 8,
//     product_title: "Lidocaine Delay Spray For Men",
//     product_subtitle: "Man Matters",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/Lidocaine+Delay+Spray+For+Men-Man+matters.png",
//     product_url: "https://manmatters.com/dp/p-lidocaine-delay-spray-men/1626/",
//   },
// ];

// // Bodies and development
// const productDevelopment = [
//   {
//     pid: 1,
//     product_title: "Stand And Pee Sticks",
//     product_subtitle: "The Woman's Company",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/stand+and+pee+stick-+the+womens+company.png",
//     product_url:
//       "https://www.thewomanscompany.com/products/stand-and-pee-stick",
//   },
//   {
//     pid: 2,
//     product_title: "Bamboo Razor",
//     product_subtitle: "The Woman's Company",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/bamboo+razor-the+womans+compant.png",
//     product_url: "https://www.thewomanscompany.com/products/bamboo-razors",
//   },
//   {
//     pid: 3,
//     product_title: "Vaginal Infection pH Screening Kit(4N)",
//     product_subtitle: "Pee Safe",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/Vaginal+Infection+pH+Screening+Kit(4N)-+peesafe.jpg",
//     product_url:
//       "https://www.peesafe.com/products/pee-safe-vaginal-infection-ph-screening-kit4n",
//   },
//   {
//     pid: 4,
//     product_title: "Neat: Body Wash",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/Neat+body+wash-+that+sassy+thing.jpg",
//     product_url:
//       "https://thatsassything.com/collections/sexual-and-menstrual-wellness/products/neat-body-wash",
//   },
//   {
//     pid: 5,
//     product_title: "Bush: Pubic Hair Oil",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/Bush+Pubic+Hair+Oil-+sassy+thing.jpg",
//     product_url:
//       "https://thatsassything.com/collections/get-off-40-and-50-discount/products/pubic-hair-oil",
//   },
//   {
//     pid: 6,
//     product_title: "Clean Qween: Underwear Detergent",
//     product_subtitle: "That Sassy Thing",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/cleanqween-that+sassy+thing.jpg",
//     product_url:
//       "https://thatsassything.com/collections/sexual-and-menstrual-wellness/products/clean-qween-underwear-detergent",
//   },
//   {
//     pid: 7,
//     product_title: "Bikini Line Trimming Razor",
//     product_subtitle: "Pee Safe",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/bikiniline+trimmer-peesafe.jpg",
//     product_url: "https://www.peesafe.com/products/furr-bikini-razor",
//   },
//   {
//     pid: 8,
//     product_title: "Face Razor",
//     product_subtitle: "Pee Safe",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/Face+Razor-Peesafe.jpg",
//     product_url: "https://www.peesafe.com/products/furr-by-pee-safe-face-razor",
//   },
//   {
//     pid: 9,
//     product_title: "Carmesi Eyebrow Razor",
//     product_subtitle: "Carmesi",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/eyebrow-razor-Carmesi.png",
//     product_url: "https://mycarmesi.com/products/eyebrow-razor",
//   },
//   {
//     pid: 10,
//     product_title: "Blink & Glow Facial Razor",
//     product_subtitle: "Sirona",
//     product_image:
//       "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/Sirona-face+razor.jpg",
//     product_url:
//       "https://www.thesirona.com/collections/face-razors/products/sirona-blink-glow-facial-razor",
//   },
// ];

// $in: for all other categories
const commonProducts = [
  {
    pid: 1,
    product_title: "Menstrual Cup",
    product_subtitle: "Sirona",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/menstrual-cup-sirona.jpg",
    product_url:
      "https://www.thesirona.com/products/sirona-reusable-menstrual-cup-for-women-medium?variant=37152483311813&currency=INR&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic&gclid=CjwKCAiAqIKNBhAIEiwAu_ZLDo_GE9CxNUUMrSHQfTavQAvEJDW80YMhSQIwwon_ycwJRiOOP5KvqxoCnaAQAvD_BwE",
  },
  {
    pid: 2,
    product_title: "Dotted Condoms ",
    product_subtitle: "Bleu Cares",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Bleu+Condoms.jpg",
    product_url:
      "https://bleucares.com/products/dotted-condoms-pack-of-8-units",
  },
  {
    pid: 3,
    product_title: "Classic Salad (Pack of 10)",
    product_subtitle: "Salad",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Contraception/Salad_Condom_Packaging_front_900x.jpg",
    product_url: "https://www.thesalad.org/products/classic",
  },
  {
    pid: 4,
    product_title: "Glide",
    product_subtitle: "My Muse",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Pleasure+Products/glide-muse.jpg",
    product_url:
      "https://mymuse.in/collections/all/products/glide-water-based-lubricant",
  },
  {
    pid: 5,
    product_title: "Stand And Pee Sticks",
    product_subtitle: "The Woman's Company",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/stand+and+pee+stick-+the+womens+company.png",
    product_url:
      "https://www.thewomanscompany.com/products/stand-and-pee-stick",
  },
  {
    pid: 6,
    product_title: "Bamboo Razor",
    product_subtitle: "The Woman's Company",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/bamboo+razor-the+womans+compant.png",
    product_url: "https://www.thewomanscompany.com/products/bamboo-razors",
  },
  {
    pid: 7,
    product_title: "Clean Qween: Underwear Detergent",
    product_subtitle: "That Sassy Thing",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Personal+Hygiene/cleanqween-that+sassy+thing.jpg",
    product_url:
      "https://thatsassything.com/collections/sexual-and-menstrual-wellness/products/clean-qween-underwear-detergent",
  },
  {
    pid: 8,
    product_title: "Tampons with Applicator",
    product_subtitle: "The Woman's Company",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Menstrual+Hygiene+Products/Tampons+with+applicator-+the+womans+company.png",
    product_url:
      "https://www.thewomanscompany.com/products/tampons-with-applicators",
  },
  {
    pid: 9,
    product_title: "Domina Female Condom",
    product_subtitle: "Peesafe",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Domina+Female+Condoms.png",
    product_url: "https://www.peesafe.com/products/pee-safe-domina-pack-of-2",
  },
  {
    pid: 10,
    product_title: "I Can Pregnancy Test Kit",
    product_subtitle: "Piramal Healthcare",
    product_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/ICAN+Pregnancy.jpg",
    product_url:
      "https://pharmeasy.in/health-care/products/i-can-pregnancy-test-kit-88380?&utm_source=google&utm_medium=cpc&utm_campaign=Shopping_OTC_Cat_FP_Sexual_Wellness&utm_adgroup=&gclid=CjwKCAjw7rWKBhAtEiwAJ3CWLGG1Wb2PSneL3bjnB9gYKq3wmjdDQLRIyHk9dx6wlCdyjMytGwzYohoC02sQAvD_BwE",
  },
];

export default [
  {
    category: [CHATBOT_TOPICS.MENSTRUATION],
    list: productMenstruation,
  },
  {
    category: [
      CHATBOT_TOPICS.SEX_CONSENT_RELATIONSHIP,
      CHATBOT_TOPICS.STD_PREGNANCY_CONTRACEPTION,
      CHATBOT_TOPICS.ABORTION_HEALTH_SERVICES,
      CHATBOT_TOPICS.VIOLENCE_AND_SAFETY,
    ],
    list: productContraception,
  },
  {
    category: [
      CHATBOT_TOPICS.UNDERSTANDING_EMOTIONS,
      CHATBOT_TOPICS.BODIES_AND_DEVELOPMENT,
      CHATBOT_TOPICS.PARENT_GUIDE,
      CHATBOT_TOPICS.MASTURBATION_AND_PLEASURE,
      CHATBOT_TOPICS.SYMPTOMS,
      CHATBOT_TOPICS.LIFE_SKILLS_DEVELOPMENT,
      CHATBOT_TOPICS.GENDER_AND_SEXUALITY,
    ],
    list: commonProducts,
  },
  // {
  //   category: [CHATBOT_TOPICS.MASTURBATION_AND_PLEASURE],
  //   list: productPleasure,
  // },
  // {
  //   category: [CHATBOT_TOPICS.BODIES_AND_DEVELOPMENT],
  //   list: productDevelopment,
  // },
];
