import {
  SIGNED_IN,
  SIGNED_OUT,
  SHOW_MODAL,
  TOGGLE_AUTH_SCREEN,
} from "../constant";

export function setShowModal(value) {
  const action = {
    type: SHOW_MODAL,
    showModal: value,
  };
  return action;
}

export function setAuthScreen(value) {
  const action = {
    type: TOGGLE_AUTH_SCREEN,
    authScreen: value,
  };
  return action;
}

export function logInUser(user) {
  const action = {
    type: SIGNED_IN,
    user,
  };
  return action;
}

export function logOutUser() {
  const action = {
    type: SIGNED_OUT,
  };
  return action;
}
